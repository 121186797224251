import { useMemo } from 'react';

import { capitalize } from '../../../utils/functions/common-utils';
import {
  AdBoardDto,
  AdBoardSectionGroupDto,
  DiscussionAdBoardSectionContentDto,
  SectionDto,
} from '../types';

export const useFilterDropdownOptions = ({
  adBoardData,
  selectedGroup,
  selectedActivity,
}: {
  adBoardData: AdBoardDto;
  selectedGroup: AdBoardSectionGroupDto | undefined;
  selectedActivity: SectionDto | undefined;
}) => {
  const groupOptions = useMemo(
    () =>
      adBoardData?.sectionGroups?.map((group) => ({
        label: `Section ${group.index + 1}: ${group.title}`,
        value: group.id,
      })) || [],
    [adBoardData?.sectionGroups]
  );
  const activityOptions = useMemo(
    () =>
      selectedGroup?.sections?.map((section) => ({
        label: `Activity ${section.index + 1} | ${capitalize(section.type)}: ${
          section.title
        }`,
        value: section.id,
      })) || [],
    [selectedGroup]
  );
  const topicOptions = useMemo(
    () =>
      selectedActivity?.type === 'discussion'
        ? selectedActivity.content?.map(
            (content: DiscussionAdBoardSectionContentDto) => ({
              label: `Topic ${content.index + 1} | ${
                content?.discussion?.topic
              }`,
              value: content.discussionId,
            })
          ) || []
        : [],
    [selectedActivity]
  );

  return [groupOptions, activityOptions, topicOptions];
};
