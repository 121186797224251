import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Card,
  Grid,
  GridItem,
  Link,
  Stack,
  Text,
} from '@chakra-ui/react';
import {
  AggregateQuestion,
  IndividualQuestion,
  SurveyEngagementDetailsDto,
} from '@keyops-cep/api-client';
import { startCase } from 'lodash';

import Loading from '../../../../components/Loading';
import MarkdownDisplay from '../../../../components/MarkdownDisplay';
import Visualization from '../../../../components/Visualization';
import { useComparableEngagements } from '../../../../hooks/useComparableEngagements';
import { useComparableQuestions } from '../../../../hooks/useComparableQuestions';
import { useEngagementDetails } from '../../../../hooks/useEngagementDetails';
import { ANALYTICS_EVENT_NAME } from '../../../../utils/constants/analytics-constants';
import { formatDateMonthYear } from '../../../../utils/functions/format-date';
import { sortComparableQuestionsAscending } from '../../../../utils/functions/sort-comparable-questions';
import i18n from '../../../../utils/i18n';
import {
  ENGAGEMENT_RESULTS_TAB,
  ENGAGEMENTS,
} from '../../../../utils/internal-routes.const';
import NotFound from '../../../NotFound';
import { AnswerDataWithDemographic } from '../../types';

const QuestionHeader = ({
  question,
}: {
  question: IndividualQuestion | AggregateQuestion;
}) => {
  return (
    <Text
      fontSize="14px"
      fontWeight={500}
      key={question.id}
      lineHeight="16px"
      textAlign="left"
      color="gray.700"
      data-testid={`question-header-${question.id}`}
    >
      {`QUESTION ${question.position + 1}`}
    </Text>
  );
};

const EngagementTitleSubHeader = ({
  engagementDetails,
}: {
  engagementDetails: SurveyEngagementDetailsDto;
}) => {
  return (
    <Text
      fontSize="14px"
      fontWeight={500}
      color="black"
      lineHeight="24px"
      noOfLines={2}
    >
      {`${
        engagementDetails.endDate
          ? `${formatDateMonthYear(engagementDetails.endDate)} |`
          : ''
      } ${engagementDetails.responseCount}/${
        engagementDetails.responseCountTarget
      } respondents`}
    </Text>
  );
};

const QuestionComparison: React.FC = () => {
  const { engagementId, comparableEngagementsId } = useParams();
  const [secondEngagementId, setSecondEngagementId] = useState<
    string | undefined
  >(undefined);

  const {
    engagementDetails,
    isLoading: isEngagementDetailsLoading,
    isError: isEngagementDetailsError,
  } = useEngagementDetails(engagementId);

  const {
    comparableEngagements,
    isLoading: isComparableEngagementsLoading,
    isError: isComparableEngagementsError,
  } = useComparableEngagements(comparableEngagementsId);

  useEffect(() => {
    if (engagementId) {
      const engagementIds = comparableEngagements?.engagements.map(
        (val) => val.id
      );
      const uniqueEngagementIds = new Set(engagementIds);
      uniqueEngagementIds.delete(engagementId);
      const secondEngagementId = [...uniqueEngagementIds][0];
      setSecondEngagementId(secondEngagementId);
    }
  }, [comparableEngagements?.engagements, engagementId]);

  const {
    engagementDetails: secondEngagementDetails,
    isLoading: isSecondEngagementDetailsLoading,
    isError: isSecondEngagementDetailsError,
  } = useEngagementDetails(secondEngagementId);

  const {
    comparableQuestions,
    isLoading: isComparableQuestionsLoading,
    isError: isComparableQuestionsError,
  } = useComparableQuestions(comparableEngagementsId);

  // Accordion on questions report
  const [showMoreStates, setShowMoreStates] = useState<{
    [key: string]: boolean;
  }>({});

  const handleShowQuestionsReportToggle = (id: string) => {
    window.analytics.track(
      ANALYTICS_EVENT_NAME.ENGAGEMENTS_COMPARISON.QUESTION_COMPARISON_REPORT,
      {
        comparableQuestionsId: id,
      }
    );

    setShowMoreStates((prevStates) => ({
      ...prevStates,
      [id]: !prevStates[id],
    }));
  };

  const isLoading =
    isComparableEngagementsLoading ||
    isSecondEngagementDetailsLoading ||
    isEngagementDetailsLoading ||
    isComparableQuestionsLoading;

  const isError =
    !comparableEngagements ||
    !comparableQuestions ||
    !engagementDetails ||
    !secondEngagementDetails ||
    isComparableQuestionsError ||
    isSecondEngagementDetailsError ||
    isComparableEngagementsError ||
    isEngagementDetailsError;

  if (isLoading) return <Loading h={'40vh'} />;

  if (isError) return <NotFound />;

  const sortedComparableQuestions = sortComparableQuestionsAscending(
    comparableQuestions,
    engagementDetails.id
  );

  return (
    <Box backgroundColor="white">
      <Grid pb="20px" templateColumns="repeat(2, 1fr)">
        {/* Main Headers */}
        <GridItem
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          colSpan={1}
        >
          <Text
            fontSize="16px"
            fontWeight={500}
            color="black"
            lineHeight="24px"
            noOfLines={2}
            data-testid={`engagement-header-${engagementDetails.id}`}
          >
            {engagementDetails.displayTitle}
          </Text>
          <EngagementTitleSubHeader engagementDetails={engagementDetails} />
        </GridItem>
        <GridItem
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          colSpan={1}
        >
          <Link
            fontSize="16px"
            fontWeight={500}
            color="black"
            lineHeight="24px"
            noOfLines={2}
            data-testid={`engagement-header-${secondEngagementDetails.id}`}
            href={`${ENGAGEMENTS}/${secondEngagementDetails.id}/${ENGAGEMENT_RESULTS_TAB}`}
            target="_blank"
            _hover={{ color: 'keyops.blue', textDecoration: 'underline' }}
          >
            {secondEngagementDetails.displayTitle}
          </Link>
          <EngagementTitleSubHeader
            engagementDetails={secondEngagementDetails}
          />
        </GridItem>
      </Grid>

      {/* Accordion Items for each ComparableQuestion */}
      {sortedComparableQuestions?.map((comparableQuestion) => {
        const sortedQuestions = comparableQuestion.questions.sort((a, b) => {
          // Ensure the item with question.engagementId === engagementDetails.id comes first
          if (a.engagementId === engagementDetails.id) return -1;
          if (b.engagementId === engagementDetails.id) return 1;
          return 0;
        });

        // Comparative report: we only display the conclusion sentence by default and have to click on "Read more" to see the full report
        const summarizedReport =
          comparableQuestion?.report?.split('**In summary**:')[1] ?? '';
        const fullReport =
          comparableQuestion?.report?.split('**In summary**:')[0] ??
          comparableQuestion?.report;

        return (
          <Accordion
            allowMultiple
            shadow="0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06);"
            borderRadius={8}
            key={comparableQuestion.id}
            defaultIndex={[0]}
            gridColumn="1 / -1"
            data-testid="comparableQuestion-accordion"
            mb="20px"
          >
            <AccordionItem borderBottomWidth="1px" border="none">
              <AccordionButton justifyContent="space-between" pl={0} py="12px">
                <Grid py="15px" templateColumns="repeat(2, 1fr)" width="100%">
                  <GridItem pl="20px" key={sortedQuestions[0].id}>
                    <QuestionHeader question={sortedQuestions[0]} />
                  </GridItem>
                  <GridItem pl="44px" key={sortedQuestions[1].id}>
                    <QuestionHeader question={sortedQuestions[1]} />
                  </GridItem>
                </Grid>
                <AccordionIcon sx={{ fontSize: '32px' }} />
              </AccordionButton>
              <AccordionPanel py={3} px={5}>
                <Grid
                  rowGap="15px"
                  columnGap="40px"
                  templateColumns="repeat(2, 1fr)"
                >
                  {sortedQuestions.map((question) => (
                    <GridItem key={question.id} colSpan={1}>
                      <Text
                        fontSize="16px"
                        data-testid={`question-content-${question.id}`}
                        fontWeight={400}
                        color="gray.700"
                      >
                        {question.label}
                      </Text>
                    </GridItem>
                  ))}
                  {/* Report */}
                  {fullReport && (
                    <GridItem
                      mt={2}
                      mb={2}
                      colSpan={2}
                      key={comparableQuestion.id}
                    >
                      <Card
                        variant={'elevated'}
                        width="100%"
                        justifyItems="center"
                        p="16px 24px"
                      >
                        <Accordion
                          allowToggle
                          data-testid={`comparable-questions-${comparableQuestion.id}-report-accordion`}
                        >
                          <AccordionItem border={'none'}>
                            <Text
                              fontSize="16px"
                              fontWeight={500}
                              color="keyops.darkerBlue"
                              pb="8px"
                            >
                              {i18n.t(
                                'engagementDetails.comparativeAnalysis.questions.report'
                              )}
                            </Text>
                            <MarkdownDisplay source={summarizedReport} />

                            <AccordionButton
                              justifyContent={'space-between'}
                              cursor={'default'}
                              _hover={{
                                backgroundColor: 'none',
                              }}
                              p={'8px 0 0 0'}
                              m={0}
                              fontSize={'14px'}
                              fontWeight={'400'}
                              onClick={() =>
                                handleShowQuestionsReportToggle(
                                  comparableQuestion.id
                                )
                              }
                            >
                              <Text color={'keyops.blue'}>
                                {showMoreStates[comparableQuestion.id]
                                  ? i18n.t(
                                      'engagementDetails.comparativeAnalysis.questions.showLess'
                                    )
                                  : i18n.t(
                                      'engagementDetails.comparativeAnalysis.questions.showMore'
                                    )}
                                <AccordionIcon />
                              </Text>
                              {!showMoreStates[comparableQuestion.id] && (
                                <Text fontSize={'12px'} color={'gray.500'}>
                                  {i18n.t(
                                    'engagementDetails.comparativeAnalysis.poweredByAI'
                                  )}
                                </Text>
                              )}
                            </AccordionButton>

                            <AccordionPanel pb={4}>
                              <MarkdownDisplay source={fullReport ?? ''} />
                              {showMoreStates[comparableQuestion.id] && (
                                <Text
                                  fontSize={'12px'}
                                  color={'gray.500'}
                                  textAlign={'right'}
                                >
                                  {i18n.t(
                                    'engagementDetails.comparativeAnalysis.poweredByAI'
                                  )}
                                </Text>
                              )}
                            </AccordionPanel>
                          </AccordionItem>
                        </Accordion>
                      </Card>
                    </GridItem>
                  )}
                  {sortedQuestions.map((question) => {
                    const engagementResponses =
                      question.engagementId === engagementDetails.id
                        ? engagementDetails.responses
                        : secondEngagementDetails.responses;

                    const questionAnswers = engagementResponses
                      .map((response) => {
                        const answer = response?.answers.find(
                          (answer) => answer?.questionId === question.id
                        );
                        return answer
                          ? {
                              ...answer,
                              demographics: response.respondentDemographics,
                            }
                          : undefined;
                      })
                      .filter(
                        (n) => n !== undefined
                      ) as AnswerDataWithDemographic[];

                    return (
                      <GridItem
                        key={question.id}
                        h={'360px'}
                        colSpan={1}
                        data-testid={`question-${question.id}-visualization`}
                      >
                        <Stack h={'100%'}>
                          <Visualization
                            key={question.id}
                            question={question}
                            questionAnswers={questionAnswers}
                            hideFilterBar={true}
                            height={'90%'} // To be able to display the question type below
                          />
                          <Text
                            fontSize="12px"
                            fontWeight={500}
                            display={'block'}
                            p={'8px 0'}
                          >
                            {i18n.t(
                              'engagementDetails.questionCards.questionType',
                              {
                                type:
                                  startCase(question.type) +
                                  (question.subType
                                    ? ' - ' + startCase(question.subType)
                                    : ''),
                              }
                            )}
                          </Text>
                        </Stack>
                      </GridItem>
                    );
                  })}
                </Grid>
              </AccordionPanel>
            </AccordionItem>
          </Accordion>
        );
      })}
    </Box>
  );
};
export default QuestionComparison;
