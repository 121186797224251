import { createContext } from 'react';
import {
  AggregateQuestion,
  IndividualQuestion,
  ResponseDetails,
  SurveyEngagementDetailsDto,
} from '@keyops-cep/api-client';

import { FilterGroupsType, ResponseFilters } from './FilterProvider';

type FilterContextTypes = {
  engagementDetails: SurveyEngagementDetailsDto;
  engagementResponses: ResponseDetails[];
  selectedFilters: ResponseFilters[];
  handleBulkSelectedFilters: (filter: ResponseFilters[]) => void;
  handleSelectedFilters: (filterOptions: ResponseFilters) => void;
  clearAllFilters: () => void;
  filterGroups: FilterGroupsType[];
  addQuestionFilter: (question: IndividualQuestion | AggregateQuestion) => void;
  removeQuestionFilter: () => void;
  DEFAULT_FILTER_GROUPS: FilterGroupsType[];
  breakoutDemographic?: string;
  setBreakoutDemographic: (value: string | undefined) => void;
};

const FilterContext = createContext<FilterContextTypes>({
  engagementDetails: {} as SurveyEngagementDetailsDto,
  engagementResponses: [],
  selectedFilters: [],
  handleBulkSelectedFilters: (filters) => undefined,
  handleSelectedFilters: (filterOptions) => undefined,
  clearAllFilters: () => undefined,
  filterGroups: [],
  addQuestionFilter: (question) => undefined,
  removeQuestionFilter: () => undefined,
  DEFAULT_FILTER_GROUPS: [],
  breakoutDemographic: undefined,
  setBreakoutDemographic: (value: string | undefined) => undefined,
});

export default FilterContext;
