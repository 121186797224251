/* tslint:disable */
/* eslint-disable */
/**
 * Keyops CEP
 * Customer Engagement Platform API documentation
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import globalAxios, { AxiosResponse, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
import { CreateSurveyAnalysisDto } from '../models';
import { QuestionAnalysis } from '../models';
import { SurveyAnalysis } from '../models';
import { UpdateSurveyAnalysisDto } from '../models';
/**
 * SurveyAnalysesApi - axios parameter creator
 * @export
 */
export const SurveyAnalysesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Creates a Survey Analysis
         * @param {CreateSurveyAnalysisDto} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        surveyAnalysisControllerCreate: async (body: CreateSurveyAnalysisDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling surveyAnalysisControllerCreate.');
            }
            const localVarPath = `/api/survey-analyses`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets all Survey Analyses
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        surveyAnalysisControllerFindAll: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/survey-analyses`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets an SurveyAnalysis by it's ID
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        surveyAnalysisControllerFindOne: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling surveyAnalysisControllerFindOne.');
            }
            const localVarPath = `/api/survey-analyses/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets the QuestionAnalyses for a SurveyAnalysis
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        surveyAnalysisControllerGetQuestionAnalyses: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling surveyAnalysisControllerGetQuestionAnalyses.');
            }
            const localVarPath = `/api/survey-analyses/{id}/question-analyses`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Deletes an Analysis
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        surveyAnalysisControllerRemove: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling surveyAnalysisControllerRemove.');
            }
            const localVarPath = `/api/survey-analyses/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Updates an SurveyAnalysis
         * @param {UpdateSurveyAnalysisDto} body 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        surveyAnalysisControllerUpdate: async (body: UpdateSurveyAnalysisDto, id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling surveyAnalysisControllerUpdate.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling surveyAnalysisControllerUpdate.');
            }
            const localVarPath = `/api/survey-analyses/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SurveyAnalysesApi - functional programming interface
 * @export
 */
export const SurveyAnalysesApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Creates a Survey Analysis
         * @param {CreateSurveyAnalysisDto} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async surveyAnalysisControllerCreate(body: CreateSurveyAnalysisDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<SurveyAnalysis>>> {
            const localVarAxiosArgs = await SurveyAnalysesApiAxiosParamCreator(configuration).surveyAnalysisControllerCreate(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Gets all Survey Analyses
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async surveyAnalysisControllerFindAll(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<Array<SurveyAnalysis>>>> {
            const localVarAxiosArgs = await SurveyAnalysesApiAxiosParamCreator(configuration).surveyAnalysisControllerFindAll(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Gets an SurveyAnalysis by it's ID
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async surveyAnalysisControllerFindOne(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<SurveyAnalysis>>> {
            const localVarAxiosArgs = await SurveyAnalysesApiAxiosParamCreator(configuration).surveyAnalysisControllerFindOne(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Gets the QuestionAnalyses for a SurveyAnalysis
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async surveyAnalysisControllerGetQuestionAnalyses(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<Array<QuestionAnalysis>>>> {
            const localVarAxiosArgs = await SurveyAnalysesApiAxiosParamCreator(configuration).surveyAnalysisControllerGetQuestionAnalyses(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Deletes an Analysis
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async surveyAnalysisControllerRemove(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<void>>> {
            const localVarAxiosArgs = await SurveyAnalysesApiAxiosParamCreator(configuration).surveyAnalysisControllerRemove(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Updates an SurveyAnalysis
         * @param {UpdateSurveyAnalysisDto} body 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async surveyAnalysisControllerUpdate(body: UpdateSurveyAnalysisDto, id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<SurveyAnalysis>>> {
            const localVarAxiosArgs = await SurveyAnalysesApiAxiosParamCreator(configuration).surveyAnalysisControllerUpdate(body, id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * SurveyAnalysesApi - factory interface
 * @export
 */
export const SurveyAnalysesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary Creates a Survey Analysis
         * @param {CreateSurveyAnalysisDto} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async surveyAnalysisControllerCreate(body: CreateSurveyAnalysisDto, options?: AxiosRequestConfig): Promise<AxiosResponse<SurveyAnalysis>> {
            return SurveyAnalysesApiFp(configuration).surveyAnalysisControllerCreate(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets all Survey Analyses
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async surveyAnalysisControllerFindAll(options?: AxiosRequestConfig): Promise<AxiosResponse<Array<SurveyAnalysis>>> {
            return SurveyAnalysesApiFp(configuration).surveyAnalysisControllerFindAll(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets an SurveyAnalysis by it's ID
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async surveyAnalysisControllerFindOne(id: string, options?: AxiosRequestConfig): Promise<AxiosResponse<SurveyAnalysis>> {
            return SurveyAnalysesApiFp(configuration).surveyAnalysisControllerFindOne(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets the QuestionAnalyses for a SurveyAnalysis
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async surveyAnalysisControllerGetQuestionAnalyses(id: string, options?: AxiosRequestConfig): Promise<AxiosResponse<Array<QuestionAnalysis>>> {
            return SurveyAnalysesApiFp(configuration).surveyAnalysisControllerGetQuestionAnalyses(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Deletes an Analysis
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async surveyAnalysisControllerRemove(id: string, options?: AxiosRequestConfig): Promise<AxiosResponse<void>> {
            return SurveyAnalysesApiFp(configuration).surveyAnalysisControllerRemove(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Updates an SurveyAnalysis
         * @param {UpdateSurveyAnalysisDto} body 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async surveyAnalysisControllerUpdate(body: UpdateSurveyAnalysisDto, id: string, options?: AxiosRequestConfig): Promise<AxiosResponse<SurveyAnalysis>> {
            return SurveyAnalysesApiFp(configuration).surveyAnalysisControllerUpdate(body, id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SurveyAnalysesApi - object-oriented interface
 * @export
 * @class SurveyAnalysesApi
 * @extends {BaseAPI}
 */
export class SurveyAnalysesApi extends BaseAPI {
    /**
     * 
     * @summary Creates a Survey Analysis
     * @param {CreateSurveyAnalysisDto} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SurveyAnalysesApi
     */
    public async surveyAnalysisControllerCreate(body: CreateSurveyAnalysisDto, options?: AxiosRequestConfig) : Promise<AxiosResponse<SurveyAnalysis>> {
        return SurveyAnalysesApiFp(this.configuration).surveyAnalysisControllerCreate(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Gets all Survey Analyses
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SurveyAnalysesApi
     */
    public async surveyAnalysisControllerFindAll(options?: AxiosRequestConfig) : Promise<AxiosResponse<Array<SurveyAnalysis>>> {
        return SurveyAnalysesApiFp(this.configuration).surveyAnalysisControllerFindAll(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Gets an SurveyAnalysis by it's ID
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SurveyAnalysesApi
     */
    public async surveyAnalysisControllerFindOne(id: string, options?: AxiosRequestConfig) : Promise<AxiosResponse<SurveyAnalysis>> {
        return SurveyAnalysesApiFp(this.configuration).surveyAnalysisControllerFindOne(id, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Gets the QuestionAnalyses for a SurveyAnalysis
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SurveyAnalysesApi
     */
    public async surveyAnalysisControllerGetQuestionAnalyses(id: string, options?: AxiosRequestConfig) : Promise<AxiosResponse<Array<QuestionAnalysis>>> {
        return SurveyAnalysesApiFp(this.configuration).surveyAnalysisControllerGetQuestionAnalyses(id, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Deletes an Analysis
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SurveyAnalysesApi
     */
    public async surveyAnalysisControllerRemove(id: string, options?: AxiosRequestConfig) : Promise<AxiosResponse<void>> {
        return SurveyAnalysesApiFp(this.configuration).surveyAnalysisControllerRemove(id, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Updates an SurveyAnalysis
     * @param {UpdateSurveyAnalysisDto} body 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SurveyAnalysesApi
     */
    public async surveyAnalysisControllerUpdate(body: UpdateSurveyAnalysisDto, id: string, options?: AxiosRequestConfig) : Promise<AxiosResponse<SurveyAnalysis>> {
        return SurveyAnalysesApiFp(this.configuration).surveyAnalysisControllerUpdate(body, id, options).then((request) => request(this.axios, this.basePath));
    }
}
