import { useQuery } from '@tanstack/react-query';

import { apiClient } from '../api/swagger-codegen-api-config';

export const useEngagementDetails = (engagementId: string | undefined) => {
  const {
    isLoading,
    isError,
    data: engagementDetails,
  } = useQuery({
    queryKey: ['engagementDetailsById', engagementId],
    queryFn: async () => {
      if (engagementId) {
        const response =
          await apiClient.surveyEngagementsApi.surveyEngagementControllerGetEngagementDetails(
            engagementId
          );
        return response.data;
      }
    },
  });

  return { engagementDetails, isLoading, isError };
};
