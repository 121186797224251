/* tslint:disable */
/* eslint-disable */
/**
 * Keyops CEP
 * Customer Engagement Platform API documentation
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/**
 * 
 * @export
 * @interface Engagement
 */
export interface Engagement {
    /**
     * The ID of the database object
     * @type {string}
     * @memberof Engagement
     */
    id: string;
    /**
     * The date the object was created
     * @type {string}
     * @memberof Engagement
     */
    createdAt: string;
    /**
     * The date the object was last updated
     * @type {string}
     * @memberof Engagement
     */
    updatedAt: string;
    /**
     * The internal name of the engagement
     * @type {string}
     * @memberof Engagement
     */
    name: string;
    /**
     * The name of the engagement displayed to the customer
     * @type {string}
     * @memberof Engagement
     */
    displayTitle: string;
    /**
     * The status of the engagement
     * @type {string}
     * @memberof Engagement
     */
    resultsStatus: EngagementResultsStatusEnum;
    /**
     * The date the engagement becomes open for responses
     * @type {string}
     * @memberof Engagement
     */
    startDate?: string;
    /**
     * The date the engagement becomes closed for responses (expires)
     * @type {string}
     * @memberof Engagement
     */
    endDate?: string;
    /**
     * The date the engagement becomes closed for responses (all responses fulfilled, or closed for some other reason)
     * @type {string}
     * @memberof Engagement
     */
    completedDate?: string;
    /**
     * The current number of responses for an engagement
     * @type {number}
     * @memberof Engagement
     */
    responseCount: number;
    /**
     * The target number of responses for an engagement
     * @type {number}
     * @memberof Engagement
     */
    responseCountTarget: number;
    /**
     * Whether or not the demographics are hidden to the customer for this engagement
     * @type {boolean}
     * @memberof Engagement
     */
    hideDemographics: boolean;
    /**
     * The status of refreshing this survey. Valid options: COMPLETED, IN_PROGRESS, FAILED
     * @type {string}
     * @memberof Engagement
     */
    refreshStatus: EngagementRefreshStatusEnum;
    /**
     * The themes associated by AI to this engagement
     * @type {string}
     * @memberof Engagement
     */
    themes: string;
    /**
     * The sub type of the Engagement
     * @type {string}
     * @memberof Engagement
     */
    discriminator: EngagementDiscriminatorEnum;
    /**
     * The ID of the tenant which owns this engagement
     * @type {string}
     * @memberof Engagement
     */
    tenantId: string;
}

/**
    * @export
    * @enum {string}
    */
export enum EngagementResultsStatusEnum {
    Draft = 'Draft',
    InProgress = 'In progress',
    ProcessingResults = 'Processing results',
    RawDataAvailable = 'Raw data available',
    InsightsAvailable = 'Insights available',
    AdminOnly = 'Admin only'
}
/**
    * @export
    * @enum {string}
    */
export enum EngagementRefreshStatusEnum {
    INPROGRESS = 'IN_PROGRESS',
    COMPLETED = 'COMPLETED',
    FAILED = 'FAILED'
}
/**
    * @export
    * @enum {string}
    */
export enum EngagementDiscriminatorEnum {
    SurveyEngagement = 'SurveyEngagement',
    AdBoardEngagement = 'AdBoardEngagement'
}

