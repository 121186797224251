import { useMemo } from 'react';
import { Outlet, useParams } from 'react-router-dom';

import Loading from '../../components/Loading';
import { useAdBoardData } from '../../hooks/useAdBoardData';
import { calculatePercentage } from '../../utils/functions/common-utils';

import BackToHome from './components/BackToHome';

const AdBoardDetailsWrapper = () => {
  const { adboardId } = useParams();
  const { data, isLoading, isError } = useAdBoardData({
    adBoardId: adboardId,
  });

  const adBoardEngagementDetails = useMemo(() => {
    if (data) {
      const { adBoardData, ...adBoardEngagement } = data;
      return adBoardEngagement;
    }
  }, [data]);

  if (isLoading) return <Loading />;
  if (isError) return <>Something went wrong</>;

  return (
    <>
      <BackToHome />
      <Outlet
        context={{
          adBoardEngagementDetails: adBoardEngagementDetails,
          adBoardData: data?.adBoardData?.adBoardDetails,
          executiveSummaryData: {
            attendees: data?.adBoardData?.participants.length || 0,
            completionRate:
              data?.responseCount && data?.responseCountTarget
                ? calculatePercentage(
                    data?.responseCount,
                    data?.responseCountTarget
                  )
                : 0,
            executiveSummaryIntro: data?.executiveSummaryIntro,
            executiveSummaryRecommendations:
              data?.executiveSummaryRecommendations,
          },
          feedData: data?.adBoardData?.progressFeed,
          participantsData: data?.adBoardData?.participants,
        }}
      />
    </>
  );
};

export default AdBoardDetailsWrapper;
