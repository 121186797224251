import { useEffect } from 'react';
import {
  Outlet,
  useLocation,
  useNavigate,
  useOutletContext,
  useParams,
} from 'react-router-dom';
import {
  Box,
  Heading,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  useToast,
} from '@chakra-ui/react';

import { KeyOpsPrimaryButton } from '../../components/ButtonComponents';
import { useAdBoardResultsExport } from '../../hooks/useAdBoardResultsExport';
import i18n from '../../utils/i18n';
import {
  ENGAGEMENT_TAB,
  EXECUTIVE_SUMMARY_TAB,
  PARTICIPANTS_TAB,
} from '../../utils/internal-routes.const';

import { AdBoardOutletContextType } from './types';

const tabRoutes = [EXECUTIVE_SUMMARY_TAB, ENGAGEMENT_TAB, PARTICIPANTS_TAB];

const downloadAdBoardResults = (data: Blob) => {
  const url = window.URL.createObjectURL(data);
  const link = document.createElement('a');
  link.href = url;
  link.download = 'export.pdf';
  document.body.appendChild(link);
  link.click();
  link.remove();
  window.URL.revokeObjectURL(url);
};

const AdBoardDetails = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { adboardId } = useParams();
  const toast = useToast();
  const { data, isLoading, refetch } = useAdBoardResultsExport(adboardId);

  const {
    adBoardEngagementDetails,
    adBoardData,
    executiveSummaryData,
    feedData,
    participantsData,
  } = useOutletContext<AdBoardOutletContextType>();

  // Extract tab index from the URL, default to 0 if not found
  const currentTabIndex = tabRoutes.findIndex((route) =>
    pathname.includes(route)
  );
  const activeTabIndex = currentTabIndex >= 0 ? currentTabIndex : 0;

  const handleTabChange = (index: number) => {
    navigate(`/adboard/${adboardId}/${tabRoutes[index]}`);
  };

  useEffect(() => {
    // Redirect to 'executive-summary' if no valid tab route is found
    if (currentTabIndex === -1) {
      navigate(`/adboard/${adboardId}/${tabRoutes[0]}`, { replace: true });
    }
  }, [currentTabIndex, adboardId, navigate]);

  const handleDownload = async () => {
    try {
      await refetch();
      if (data) {
        downloadAdBoardResults(data);
        toast({
          title: i18n.t('common.success'),
          description: i18n.t('adBoardDetails.exportSuccessMsg'),
          variant: 'subtle',
          isClosable: true,
          status: 'success',
          position: 'top-right',
        });
      }
    } catch (e) {
      console.error(e);
      toast({
        title: i18n.t('errorComponent.title'),
        description: i18n.t('adBoardDetails.exportErrorMsg'),
        variant: 'subtle',
        isClosable: true,
        status: 'error',
        position: 'top-right',
      });
    }
  };

  return (
    <>
      <Box
        display={'flex'}
        alignItems={'center'}
        justifyContent={'space-between'}
        mt={4}
      >
        <Heading as="h3" fontSize={24} fontWeight={600} textAlign="left">
          {adBoardEngagementDetails.displayTitle}
        </Heading>
        <KeyOpsPrimaryButton
          size={'sm'}
          isLoading={isLoading}
          onClick={handleDownload}
        >
          {i18n.t('adBoardDetails.exportResults')}
        </KeyOpsPrimaryButton>
      </Box>
      <Tabs index={activeTabIndex} onChange={handleTabChange} mt={6}>
        <TabList>
          <Tab>{i18n.t('adBoardDetails.executiveSummaryTab')}</Tab>
          <Tab>{i18n.t('adBoardDetails.engagementTab')}</Tab>
          <Tab>{i18n.t('adBoardDetails.participantsTab')}</Tab>
        </TabList>

        <TabPanels>
          {tabRoutes.map((_, index) => (
            <TabPanel key={index}>
              <Outlet
                context={
                  {
                    adBoardEngagementDetails,
                    adBoardData,
                    executiveSummaryData,
                    feedData,
                    participantsData,
                  } satisfies AdBoardOutletContextType
                }
              />
            </TabPanel>
          ))}
        </TabPanels>
      </Tabs>
    </>
  );
};

export default AdBoardDetails;
