/* tslint:disable */
/* eslint-disable */
/**
 * Keyops CEP
 * Customer Engagement Platform API documentation
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import globalAxios, { AxiosResponse, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
import { EmailPasswordLoginDto } from '../models';
import { EmailPasswordResetCompleteDto } from '../models';
import { EmailPasswordResetInitiateDto } from '../models';
import { EmailPasswordSignupDto } from '../models';
import { Tokens } from '../models';
import { UserProfileDto } from '../models';
import { VerifySignupTokenResponseDto } from '../models';
/**
 * AuthenticationApi - axios parameter creator
 * @export
 */
export const AuthenticationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Logs in a user with their Azure ID token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerAzureLogin: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/auth/azure-login`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AzureBearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Signs up a user with their Azure ID token
         * @param {string} signupToken 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerAzureSignup: async (signupToken: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'signupToken' is not null or undefined
            if (signupToken === null || signupToken === undefined) {
                throw new RequiredError('signupToken','Required parameter signupToken was null or undefined when calling authControllerAzureSignup.');
            }
            const localVarPath = `/api/auth/azure-signup`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AzureBearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (signupToken !== undefined) {
                localVarQueryParameter['signupToken'] = signupToken;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Logs in a user by email and password
         * @param {EmailPasswordLoginDto} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerEmailPasswordLogin: async (body: EmailPasswordLoginDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling authControllerEmailPasswordLogin.');
            }
            const localVarPath = `/api/auth/email-password-login`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Completes a password reset for a user
         * @param {EmailPasswordResetCompleteDto} body 
         * @param {string} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerEmailPasswordResetComplete: async (body: EmailPasswordResetCompleteDto, token: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling authControllerEmailPasswordResetComplete.');
            }
            // verify required parameter 'token' is not null or undefined
            if (token === null || token === undefined) {
                throw new RequiredError('token','Required parameter token was null or undefined when calling authControllerEmailPasswordResetComplete.');
            }
            const localVarPath = `/api/auth/password-reset-complete`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (token !== undefined) {
                localVarQueryParameter['token'] = token;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Initiates a password reset for a user
         * @param {EmailPasswordResetInitiateDto} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerEmailPasswordResetInitiate: async (body: EmailPasswordResetInitiateDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling authControllerEmailPasswordResetInitiate.');
            }
            const localVarPath = `/api/auth/password-reset-initiate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Signs up a User by Email and Password
         * @param {EmailPasswordSignupDto} body 
         * @param {string} signupToken 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerEmailPasswordSignup: async (body: EmailPasswordSignupDto, signupToken: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling authControllerEmailPasswordSignup.');
            }
            // verify required parameter 'signupToken' is not null or undefined
            if (signupToken === null || signupToken === undefined) {
                throw new RequiredError('signupToken','Required parameter signupToken was null or undefined when calling authControllerEmailPasswordSignup.');
            }
            const localVarPath = `/api/auth/email-password-signup`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (signupToken !== undefined) {
                localVarQueryParameter['signupToken'] = signupToken;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns user or admin object for the authenticated userId from request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerGetUserProfile: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/auth/user-profile`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Logs in an admin with their Google ID token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerGoogleLogin: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/auth/google-login`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication GoogleBearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Logs out a user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerLogout: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/auth/logout`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Refreshes a user's tokens
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerRefreshTokens: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/auth/refresh-tokens`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication RefreshToken required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("x-refresh-token")
                    : await configuration.apiKey;
                localVarHeaderParameter["x-refresh-token"] = localVarApiKeyValue;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Validates a reset password token
         * @param {string} resetPasswordToken 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerValidateResetPasswordToken: async (resetPasswordToken: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'resetPasswordToken' is not null or undefined
            if (resetPasswordToken === null || resetPasswordToken === undefined) {
                throw new RequiredError('resetPasswordToken','Required parameter resetPasswordToken was null or undefined when calling authControllerValidateResetPasswordToken.');
            }
            const localVarPath = `/api/auth/validate-reset-password-token`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (resetPasswordToken !== undefined) {
                localVarQueryParameter['resetPasswordToken'] = resetPasswordToken;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Validates a signup token
         * @param {string} signupToken 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerValidateSignupToken: async (signupToken: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'signupToken' is not null or undefined
            if (signupToken === null || signupToken === undefined) {
                throw new RequiredError('signupToken','Required parameter signupToken was null or undefined when calling authControllerValidateSignupToken.');
            }
            const localVarPath = `/api/auth/validate-signup-token`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (signupToken !== undefined) {
                localVarQueryParameter['signupToken'] = signupToken;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Validates that a user is authenticated
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerValidateUser: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/auth/validate-user`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AuthenticationApi - functional programming interface
 * @export
 */
export const AuthenticationApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Logs in a user with their Azure ID token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authControllerAzureLogin(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<Tokens>>> {
            const localVarAxiosArgs = await AuthenticationApiAxiosParamCreator(configuration).authControllerAzureLogin(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Signs up a user with their Azure ID token
         * @param {string} signupToken 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authControllerAzureSignup(signupToken: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<Tokens>>> {
            const localVarAxiosArgs = await AuthenticationApiAxiosParamCreator(configuration).authControllerAzureSignup(signupToken, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Logs in a user by email and password
         * @param {EmailPasswordLoginDto} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authControllerEmailPasswordLogin(body: EmailPasswordLoginDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<Tokens>>> {
            const localVarAxiosArgs = await AuthenticationApiAxiosParamCreator(configuration).authControllerEmailPasswordLogin(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Completes a password reset for a user
         * @param {EmailPasswordResetCompleteDto} body 
         * @param {string} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authControllerEmailPasswordResetComplete(body: EmailPasswordResetCompleteDto, token: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<Tokens>>> {
            const localVarAxiosArgs = await AuthenticationApiAxiosParamCreator(configuration).authControllerEmailPasswordResetComplete(body, token, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Initiates a password reset for a user
         * @param {EmailPasswordResetInitiateDto} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authControllerEmailPasswordResetInitiate(body: EmailPasswordResetInitiateDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<void>>> {
            const localVarAxiosArgs = await AuthenticationApiAxiosParamCreator(configuration).authControllerEmailPasswordResetInitiate(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Signs up a User by Email and Password
         * @param {EmailPasswordSignupDto} body 
         * @param {string} signupToken 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authControllerEmailPasswordSignup(body: EmailPasswordSignupDto, signupToken: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<Tokens>>> {
            const localVarAxiosArgs = await AuthenticationApiAxiosParamCreator(configuration).authControllerEmailPasswordSignup(body, signupToken, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Returns user or admin object for the authenticated userId from request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authControllerGetUserProfile(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<UserProfileDto>>> {
            const localVarAxiosArgs = await AuthenticationApiAxiosParamCreator(configuration).authControllerGetUserProfile(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Logs in an admin with their Google ID token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authControllerGoogleLogin(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<Tokens>>> {
            const localVarAxiosArgs = await AuthenticationApiAxiosParamCreator(configuration).authControllerGoogleLogin(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Logs out a user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authControllerLogout(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<void>>> {
            const localVarAxiosArgs = await AuthenticationApiAxiosParamCreator(configuration).authControllerLogout(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Refreshes a user's tokens
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authControllerRefreshTokens(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<Tokens>>> {
            const localVarAxiosArgs = await AuthenticationApiAxiosParamCreator(configuration).authControllerRefreshTokens(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Validates a reset password token
         * @param {string} resetPasswordToken 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authControllerValidateResetPasswordToken(resetPasswordToken: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<void>>> {
            const localVarAxiosArgs = await AuthenticationApiAxiosParamCreator(configuration).authControllerValidateResetPasswordToken(resetPasswordToken, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Validates a signup token
         * @param {string} signupToken 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authControllerValidateSignupToken(signupToken: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<VerifySignupTokenResponseDto>>> {
            const localVarAxiosArgs = await AuthenticationApiAxiosParamCreator(configuration).authControllerValidateSignupToken(signupToken, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Validates that a user is authenticated
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authControllerValidateUser(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<void>>> {
            const localVarAxiosArgs = await AuthenticationApiAxiosParamCreator(configuration).authControllerValidateUser(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * AuthenticationApi - factory interface
 * @export
 */
export const AuthenticationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary Logs in a user with their Azure ID token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authControllerAzureLogin(options?: AxiosRequestConfig): Promise<AxiosResponse<Tokens>> {
            return AuthenticationApiFp(configuration).authControllerAzureLogin(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Signs up a user with their Azure ID token
         * @param {string} signupToken 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authControllerAzureSignup(signupToken: string, options?: AxiosRequestConfig): Promise<AxiosResponse<Tokens>> {
            return AuthenticationApiFp(configuration).authControllerAzureSignup(signupToken, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Logs in a user by email and password
         * @param {EmailPasswordLoginDto} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authControllerEmailPasswordLogin(body: EmailPasswordLoginDto, options?: AxiosRequestConfig): Promise<AxiosResponse<Tokens>> {
            return AuthenticationApiFp(configuration).authControllerEmailPasswordLogin(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Completes a password reset for a user
         * @param {EmailPasswordResetCompleteDto} body 
         * @param {string} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authControllerEmailPasswordResetComplete(body: EmailPasswordResetCompleteDto, token: string, options?: AxiosRequestConfig): Promise<AxiosResponse<Tokens>> {
            return AuthenticationApiFp(configuration).authControllerEmailPasswordResetComplete(body, token, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Initiates a password reset for a user
         * @param {EmailPasswordResetInitiateDto} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authControllerEmailPasswordResetInitiate(body: EmailPasswordResetInitiateDto, options?: AxiosRequestConfig): Promise<AxiosResponse<void>> {
            return AuthenticationApiFp(configuration).authControllerEmailPasswordResetInitiate(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Signs up a User by Email and Password
         * @param {EmailPasswordSignupDto} body 
         * @param {string} signupToken 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authControllerEmailPasswordSignup(body: EmailPasswordSignupDto, signupToken: string, options?: AxiosRequestConfig): Promise<AxiosResponse<Tokens>> {
            return AuthenticationApiFp(configuration).authControllerEmailPasswordSignup(body, signupToken, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns user or admin object for the authenticated userId from request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authControllerGetUserProfile(options?: AxiosRequestConfig): Promise<AxiosResponse<UserProfileDto>> {
            return AuthenticationApiFp(configuration).authControllerGetUserProfile(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Logs in an admin with their Google ID token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authControllerGoogleLogin(options?: AxiosRequestConfig): Promise<AxiosResponse<Tokens>> {
            return AuthenticationApiFp(configuration).authControllerGoogleLogin(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Logs out a user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authControllerLogout(options?: AxiosRequestConfig): Promise<AxiosResponse<void>> {
            return AuthenticationApiFp(configuration).authControllerLogout(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Refreshes a user's tokens
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authControllerRefreshTokens(options?: AxiosRequestConfig): Promise<AxiosResponse<Tokens>> {
            return AuthenticationApiFp(configuration).authControllerRefreshTokens(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Validates a reset password token
         * @param {string} resetPasswordToken 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authControllerValidateResetPasswordToken(resetPasswordToken: string, options?: AxiosRequestConfig): Promise<AxiosResponse<void>> {
            return AuthenticationApiFp(configuration).authControllerValidateResetPasswordToken(resetPasswordToken, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Validates a signup token
         * @param {string} signupToken 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authControllerValidateSignupToken(signupToken: string, options?: AxiosRequestConfig): Promise<AxiosResponse<VerifySignupTokenResponseDto>> {
            return AuthenticationApiFp(configuration).authControllerValidateSignupToken(signupToken, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Validates that a user is authenticated
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authControllerValidateUser(options?: AxiosRequestConfig): Promise<AxiosResponse<void>> {
            return AuthenticationApiFp(configuration).authControllerValidateUser(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AuthenticationApi - object-oriented interface
 * @export
 * @class AuthenticationApi
 * @extends {BaseAPI}
 */
export class AuthenticationApi extends BaseAPI {
    /**
     * 
     * @summary Logs in a user with their Azure ID token
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationApi
     */
    public async authControllerAzureLogin(options?: AxiosRequestConfig) : Promise<AxiosResponse<Tokens>> {
        return AuthenticationApiFp(this.configuration).authControllerAzureLogin(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Signs up a user with their Azure ID token
     * @param {string} signupToken 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationApi
     */
    public async authControllerAzureSignup(signupToken: string, options?: AxiosRequestConfig) : Promise<AxiosResponse<Tokens>> {
        return AuthenticationApiFp(this.configuration).authControllerAzureSignup(signupToken, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Logs in a user by email and password
     * @param {EmailPasswordLoginDto} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationApi
     */
    public async authControllerEmailPasswordLogin(body: EmailPasswordLoginDto, options?: AxiosRequestConfig) : Promise<AxiosResponse<Tokens>> {
        return AuthenticationApiFp(this.configuration).authControllerEmailPasswordLogin(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Completes a password reset for a user
     * @param {EmailPasswordResetCompleteDto} body 
     * @param {string} token 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationApi
     */
    public async authControllerEmailPasswordResetComplete(body: EmailPasswordResetCompleteDto, token: string, options?: AxiosRequestConfig) : Promise<AxiosResponse<Tokens>> {
        return AuthenticationApiFp(this.configuration).authControllerEmailPasswordResetComplete(body, token, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Initiates a password reset for a user
     * @param {EmailPasswordResetInitiateDto} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationApi
     */
    public async authControllerEmailPasswordResetInitiate(body: EmailPasswordResetInitiateDto, options?: AxiosRequestConfig) : Promise<AxiosResponse<void>> {
        return AuthenticationApiFp(this.configuration).authControllerEmailPasswordResetInitiate(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Signs up a User by Email and Password
     * @param {EmailPasswordSignupDto} body 
     * @param {string} signupToken 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationApi
     */
    public async authControllerEmailPasswordSignup(body: EmailPasswordSignupDto, signupToken: string, options?: AxiosRequestConfig) : Promise<AxiosResponse<Tokens>> {
        return AuthenticationApiFp(this.configuration).authControllerEmailPasswordSignup(body, signupToken, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Returns user or admin object for the authenticated userId from request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationApi
     */
    public async authControllerGetUserProfile(options?: AxiosRequestConfig) : Promise<AxiosResponse<UserProfileDto>> {
        return AuthenticationApiFp(this.configuration).authControllerGetUserProfile(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Logs in an admin with their Google ID token
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationApi
     */
    public async authControllerGoogleLogin(options?: AxiosRequestConfig) : Promise<AxiosResponse<Tokens>> {
        return AuthenticationApiFp(this.configuration).authControllerGoogleLogin(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Logs out a user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationApi
     */
    public async authControllerLogout(options?: AxiosRequestConfig) : Promise<AxiosResponse<void>> {
        return AuthenticationApiFp(this.configuration).authControllerLogout(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Refreshes a user's tokens
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationApi
     */
    public async authControllerRefreshTokens(options?: AxiosRequestConfig) : Promise<AxiosResponse<Tokens>> {
        return AuthenticationApiFp(this.configuration).authControllerRefreshTokens(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Validates a reset password token
     * @param {string} resetPasswordToken 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationApi
     */
    public async authControllerValidateResetPasswordToken(resetPasswordToken: string, options?: AxiosRequestConfig) : Promise<AxiosResponse<void>> {
        return AuthenticationApiFp(this.configuration).authControllerValidateResetPasswordToken(resetPasswordToken, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Validates a signup token
     * @param {string} signupToken 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationApi
     */
    public async authControllerValidateSignupToken(signupToken: string, options?: AxiosRequestConfig) : Promise<AxiosResponse<VerifySignupTokenResponseDto>> {
        return AuthenticationApiFp(this.configuration).authControllerValidateSignupToken(signupToken, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Validates that a user is authenticated
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationApi
     */
    public async authControllerValidateUser(options?: AxiosRequestConfig) : Promise<AxiosResponse<void>> {
        return AuthenticationApiFp(this.configuration).authControllerValidateUser(options).then((request) => request(this.axios, this.basePath));
    }
}
