// External Dependencies
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

// Internal Dependencies
import ErrorComponent from '../../components/ErrorComponent';
import Loading from '../../components/Loading';
import FilterProvider from '../../HOCs/Filter/FilterProvider';
import { useEngagementDetails } from '../../hooks/useEngagementDetails';

import { IndividualUserRawResults } from './components/IndividualUserRawResults';
import EngagementDetailsResults from './pages/Results';
import EngagementDetailsHome from './EngagementDetailsHome';

export type FilterType = {
  questionId?: string;
  type: string;
  options: (string | number | boolean | string[] | undefined)[];
};

const EngagementDetails = ({
  adBoardSurveyEngagementId,
  showIndividualUserRawResultsView = false,
}: {
  adBoardSurveyEngagementId?: string;
  showIndividualUserRawResultsView?: boolean;
}) => {
  const { engagementId: urlEngagementId } = useParams();
  const engagementId = urlEngagementId || adBoardSurveyEngagementId;

  const { engagementDetails, isLoading, isError } =
    useEngagementDetails(engagementId);

  useEffect(() => {
    const container = document.getElementById('container');
    const originalMaxWidth = container?.style.maxWidth ?? '';
    if (container) {
      container.style.maxWidth = '1600px';
    }

    //return function for unhooking
    return () => {
      if (container) {
        container.style.maxWidth = originalMaxWidth;
      }
    };
  }, []);

  if (isLoading) return <Loading />;
  if (!engagementId || !engagementDetails || isError) return <ErrorComponent />;

  if (showIndividualUserRawResultsView)
    return <IndividualUserRawResults engagementDetails={engagementDetails} />;

  return (
    <FilterProvider value={{ engagementDetails }}>
      {!adBoardSurveyEngagementId ? (
        <EngagementDetailsHome />
      ) : (
        <EngagementDetailsResults adBoardSurvey />
      )}
    </FilterProvider>
  );
};

export default EngagementDetails;
