import { useOutletContext } from 'react-router-dom';
import { Box, Divider, Grid, Heading } from '@chakra-ui/react';

import MarkdownDisplay from '../../components/MarkdownDisplay';
import i18n from '../../utils/i18n';

import { InsightsEmptyState } from './components/InsightsEmptyState';
import SummaryDemoCard from './components/SummaryDemoCard';
import { AdBoardOutletContextType } from './types';

export const AdBoardExecutiveSummary = () => {
  const { executiveSummaryData } = useOutletContext<AdBoardOutletContextType>();
  const {
    attendees,
    completionRate,
    executiveSummaryIntro,
    executiveSummaryRecommendations,
  } = executiveSummaryData;

  return (
    <>
      <Grid templateColumns="repeat(5, 1fr)" gap={6} mt={4}>
        <SummaryDemoCard
          title={i18n.t('adBoardDetails.executiveSummary.attendees')}
          value={attendees}
        />
        <SummaryDemoCard
          title={i18n.t('adBoardDetails.executiveSummary.completionRate')}
          value={`${completionRate}%`}
        />
      </Grid>
      {!executiveSummaryIntro && !executiveSummaryRecommendations && (
        <Box mt={8}>
          <InsightsEmptyState />
        </Box>
      )}
      {!!executiveSummaryIntro && (
        <Box mt={8}>
          <MarkdownDisplay source={executiveSummaryIntro} />
        </Box>
      )}
      {!!executiveSummaryRecommendations && (
        <>
          <Heading mt={10} mb={2} size={'sm'}>
            {i18n.t('adBoardDetails.executiveSummary.recommendations')}
          </Heading>
          <Divider />
          <Box mt={8}>
            <MarkdownDisplay source={executiveSummaryRecommendations} />
          </Box>
        </>
      )}
    </>
  );
};
