import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { Box, Card, CardBody, Text, VStack } from '@chakra-ui/react';
import {
  IndividualAnswer,
  SurveyEngagementDetailsDto,
} from '@keyops-cep/api-client';
import { sortBy } from 'lodash';

import i18n from '../../../utils/i18n';

export const IndividualUserRawResults = ({
  engagementDetails,
  userId,
}: {
  engagementDetails: SurveyEngagementDetailsDto;
  userId?: number;
}) => {
  const { userId: userIdFromUrl } = useParams();
  const currentUserId = String(userIdFromUrl || userId);

  // Memoized user answers
  const userAnswers = useMemo(
    () =>
      engagementDetails.responses.find((response) =>
        response.respondentDemographics.some(
          ({ surveySparrowId, value }) =>
            surveySparrowId === 'Contact_HcpId' &&
            String(value) === String(currentUserId)
        )
      )?.answers ?? [],
    [currentUserId, engagementDetails.responses]
  );

  // Memoized sorted questions with user answers
  const renderResponses = useMemo(
    () =>
      sortBy(engagementDetails.questions, 'position').map((question) => {
        const answer = userAnswers?.find(
          (answer) => answer.questionId === question.id
        ) as IndividualAnswer;

        // If answer.value is an array, join with commas
        const displayAnswer = Array.isArray(answer?.value)
          ? answer.value.join(', ')
          : answer?.value ?? 'N/A';

        return (
          <Card key={question.id} variant={'outline'} w={'100%'}>
            <CardBody py={3}>
              <VStack align="flex-start" gap={4}>
                <Box>
                  <Text fontSize="xs" color={'gray.500'}>
                    {i18n.t('engagementDetails.questionCards.question', {
                      questionNo: question.position + 1,
                    })}
                  </Text>
                  <Text>{question.label}</Text>
                </Box>
                <Box>
                  <Text>{displayAnswer}</Text>
                </Box>
              </VStack>
            </CardBody>
          </Card>
        );
      }),
    [engagementDetails.questions, userAnswers]
  );

  return (
    <VStack align="flex-start" gap={2}>
      {renderResponses}
    </VStack>
  );
};
