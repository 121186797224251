import {
  AggregateAnswer,
  AggregateQuestion,
  IndividualAnswer,
  RespondentDemographic,
  SurveyEngagementDetailsDto,
} from '@keyops-cep/api-client';
import Papa, { UnparseObject } from 'papaparse';

import { AnswerComponents } from '../../pages/EngagementDetails/types';

type Question = { id: string; label: string; index: number };
type ColumnHeaders = ['Response', ...string[]];
type AnswerPerRespondent = {
  questionId: string;
  value: string | number | true | string[] | AnswerComponents[] | undefined;
};
type ResponsePerRespondent = {
  response: number;
  demographic: Record<string, string | string[]>;
  answers: AnswerPerRespondent[];
};

/**
 * Takes all the demographic parameters available in engagementData
 * and returns their names in an array
 * @param {EngagementDetailsDto} engagementData - The survey data from SurveySparrow we formatted for enagegement details.
 */
export const buildDemographicColumnsHeaders = (
  engagementData: SurveyEngagementDetailsDto
): string[] => {
  const demographicColumns: string[] = [];
  const respondentDemographics = engagementData.responses.map(
    (response) => response.respondentDemographics
  );
  respondentDemographics.forEach((respondentDemographic) => {
    if (!respondentDemographic) {
      return;
    }
    respondentDemographic.forEach((singleRespondentDemographic) => {
      const demographicHeader = getDemographicHeader(
        singleRespondentDemographic
      );
      if (
        !demographicColumns.find((col: string) => col === demographicHeader)
      ) {
        demographicColumns.push(demographicHeader);
      }
    });
  });
  return demographicColumns;
};

const getDemographicHeader = (
  singleRespondentDemographic: RespondentDemographic
) => {
  const demographicPrefix = 'Demographic: ';
  return demographicPrefix + singleRespondentDemographic.name;
};

/**
 * Takes all the questions available in engagementData, put their id and label in an object
 * and returns these in an array
 * @param {EngagementDetailsDto} engagementData - The survey data from SurveySparrow we formatted for enagegement details.
 */
export const buildQuestions = (
  engagementData: SurveyEngagementDetailsDto
): Question[] => {
  const questionPrefix = 'Question';
  return engagementData.questions
    .reduce((formattedQuestions: Question[], question, currentIndex) => {
      const formattedQuestion = {
        id: question.id,
        label: question.label,
        index: question.position !== undefined ? question.position + 1 : 99,
      };
      if (Object.keys(question).includes('components')) {
        const subQuestions = (question as AggregateQuestion).components;
        return [
          ...formattedQuestions,
          ...subQuestions.map((subQuestion, subIndex) => ({
            id: question.id + '.' + subQuestion.surveySparrowId,
            label: `${questionPrefix} ${formattedQuestion.index}.${
              subIndex + 1
            }: ${question.label}: ${subQuestion.label}`,
            index: formattedQuestion.index + (subIndex + 1) / 10,
          })),
        ];
      } else {
        formattedQuestion.label = `${questionPrefix} ${formattedQuestion.index}: ${question.label}`;
        return [...formattedQuestions, formattedQuestion];
      }
    }, [])
    .sort((a, b) => a.index - b.index);
};

/**
 * Takes all the demographics available in a Survey Sparrow response
 * and returns an object where each key is a demographic parameter and each value a string or an array of string
 * @param demographics Respondent[] - The demographics of one Survey Sparrow response
 */
export const buildDemographics = (
  demographics: RespondentDemographic[] | undefined
): Record<string, string | string[]> => {
  if (!demographics) {
    return {};
  }
  return demographics.reduce((acc: Record<string, string | string[]>, demo) => {
    const demographicHeader = getDemographicHeader(demo);
    acc[demographicHeader] = demo.value;

    return acc;
  }, {});
};

/**
 * Takes all the answers and answers coponents available in engagementData
 * For each, build a ResponsePerRespondent object with these keys/values:
 *  - response: timestamp of response.createdAt
 *  - each demographic parameter's name: its corresponding value
 *  - answers: array of AnswerPerRespondent where...
 *    - questionId: answer.questionId
 *    - value: value of an answer or an answer's component
 * @param {EngagementDetailsDto} engagementData - The survey data from SurveySparrow we formatted for engagement details.
 * @returns {ResponsePerRespondent[]} - The list of ResponsePerRespondent, sorted by createdAt (earlier first)
 */
export const buildAnswersPerParticipant = (
  engagementData: SurveyEngagementDetailsDto
): ResponsePerRespondent[] => {
  const responsesPerRespondent: ResponsePerRespondent[] = [];

  engagementData.responses.forEach((response) => {
    const formattedAnswers: AnswerPerRespondent[] = [];
    response.answers.forEach((answer, index) => {
      if (!answer) {
        console.log(
          `Warning: no answer for response `,
          response.id,
          ` at index`,
          index
        );
      }
      const questionId = answer.questionId;
      if (!Object.keys(answer).includes('components')) {
        const individualAnswer = answer as IndividualAnswer;
        const value = individualAnswer.value
          ? individualAnswer.value.toString()
          : '';
        formattedAnswers.push({
          questionId,
          value,
        });
      } else {
        const aggregateAnswer = answer as AggregateAnswer;
        aggregateAnswer.components.forEach((subAnswer) => {
          const value = subAnswer
            ? subAnswer.value
              ? subAnswer.value.toString()
              : ''
            : '';

          const formattedAnswer = {
            questionId:
              questionId + '.' + subAnswer.questionComponentSurveySparrowId,
            value,
          };
          formattedAnswers.push(formattedAnswer);
        });
      }
    });

    responsesPerRespondent.push({
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      response: new Date(response.completedAt!).getTime(),
      demographic: buildDemographics(response.respondentDemographics),
      answers: formattedAnswers,
    });
  });

  // Sort by date
  responsesPerRespondent.sort((response1, response2) => {
    return response1.response - response2.response;
  });

  return responsesPerRespondent;
};

/**
 * buildCSVData formats each row of the future CSV accordinly each header
 * (time of response, demographic headers and each question and sub-question)
 * @param {ResponsePerRespondent[]} responsesPerRespondent - Array of response per respondent
 * @param {string[]} demographicColumns - Array of all demographic columns headers
 * @param { { id: string; label: string }[]} questionTextColumns - Array of Records containing id and label of each question and subquestion
 * @returns {(string | number | true | string[] | AnswerComponents[])[][]} - Double array representing the data to put in the CSV
 */
export const buildCSVData = (
  responsesPerRespondent: ResponsePerRespondent[],
  demographicColumns: string[],
  questionTextColumns: { id: string; label: string }[]
): (string | number | true | string[] | AnswerComponents[])[][] => {
  return responsesPerRespondent.map((respondentResponse) => {
    return [
      `Response: ${new Intl.DateTimeFormat('en-CA', {
        dateStyle: 'medium',
        timeStyle: 'short',
      }).format(respondentResponse.response)}`,
      ...demographicColumns.map(
        (demo) => respondentResponse.demographic[demo] ?? ''
      ),
      ...questionTextColumns.map(({ id }) => {
        const answer = respondentResponse.answers.find((el) => {
          return el.questionId === id;
        });
        if (!answer) {
          return ``;
        }
        const { value } = answer;
        if (!value) {
          return ``;
        }
        if (value === 'TRUE') {
          return `yes`;
        }
        if (value === 'FALSE') {
          return `no`;
        }

        return value;
      }),
    ];
  });
};

export const exportData = (
  data: string,
  fileName: string,
  type: string,
  questionsCount: number,
  respondentsCount: number
) => {
  try {
    const blob = new Blob([data], { type });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = fileName;
    a.click();
    window.URL.revokeObjectURL(url);
    console.log(
      `Parsing complete for ${questionsCount} questions and subquestions and ${respondentsCount} respondents.`
    );
  } catch (e) {
    console.error(
      `Fail to export the CSV for ${questionsCount} questions and subquestions and ${respondentsCount} respondents. \n ${e}`
    );
  }
};

export const exportSurveyResult = (
  engagementData: SurveyEngagementDetailsDto,
  fileName: string,
  type: string
) => {
  if (!engagementData || !engagementData.responses) {
    return;
  }
  // Build the columnsHeaders
  const demographicColumnsHeaders: string[] =
    buildDemographicColumnsHeaders(engagementData);

  const questionTexts = buildQuestions(engagementData);
  const columnsHeaders: ColumnHeaders = [
    'Response',
    ...demographicColumnsHeaders,
    ...questionTexts.map((questionTextColumn) => questionTextColumn.label),
  ];

  // Format the responses
  const responsesPerRespondent = buildAnswersPerParticipant(engagementData);

  const finalData = buildCSVData(
    responsesPerRespondent,
    demographicColumnsHeaders,
    questionTexts
  );

  const csvData: UnparseObject<unknown> = {
    fields: columnsHeaders,
    data: finalData,
  };
  const config = {
    quotes: false,
    delimiter: ',',
    header: true,
    skipEmptyLines: false,
  };
  const CSVData = Papa.unparse(csvData, config);

  const questionsCount = questionTexts.length;
  const respondentsCount = engagementData.responseCount;
  exportData(CSVData, fileName, type, questionsCount, respondentsCount);
};
