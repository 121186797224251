import { useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  AbsoluteCenter,
  Box,
  Button,
  Divider,
  Flex,
  Link,
  Text,
  useToast,
} from '@chakra-ui/react';
import { useQuery } from '@tanstack/react-query';

import { validateSignupToken } from '../../api/auth.functions';
import Loading from '../../components/Loading';
import RedirectToPath from '../../components/Navigation/redirect-to-dashboard';
import PasswordField from '../../components/PasswordField';
import PasswordStrengthBar from '../../components/PasswordStrengthBar';
import useAuth from '../../HOCs/auth/useAuth';
import usePasswordStrength from '../../hooks/usePasswordStrength';
import i18n from '../../utils/i18n';
import { DASHBOARD, LOGIN } from '../../utils/internal-routes.const';

const LinkExpired = () => (
  <Box>
    <Text fontSize="xl" mt={6}>
      {i18n.t('signup.emailPasswordSignup.title')}
    </Text>
    <Text fontSize="md" mt={6}>
      {i18n.t('signup.emailPasswordSignup.linkExpired')}
    </Text>
    <Box mt={10}>
      <Link href={LOGIN} color="keyops.blue" rel="noopener noreferrer">
        {i18n.t('signup.emailPasswordSignup.backToLogin')}
      </Link>
    </Box>
  </Box>
);

const EmailPasswordSignup: React.FC = () => {
  const {
    loading,
    handleEmailPasswordSignup,
    handleAzureSignup,
    isLoggedInFromApi,
  } = useAuth();
  const toast = useToast();
  const { signupToken } = useParams();
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [passwordMatchMessage, setPasswordMatchMessage] = useState('');

  const {
    data: validateSignupTokenData,
    isLoading,
    isError,
  } = useQuery({
    queryKey: ['validateSignupToken', signupToken],
    queryFn: async () => {
      if (signupToken) {
        const data = await validateSignupToken({ signupToken });
        return data;
      }
    },
  });
  const result = usePasswordStrength(password);

  const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value);
  };

  const handleConfirmPasswordChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setConfirmPassword(event.target.value);
    setPasswordMatchMessage('');
  };

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    if (password !== confirmPassword) {
      setPasswordMatchMessage(
        i18n.t('signup.emailPasswordSignup.passwordMatchError')
      );
    } else if (signupToken) {
      handleEmailPasswordSignup(signupToken, password);
      toast({
        title: i18n.t('common.success'),
        description: i18n.t('signup.emailPasswordSignup.successMsg'),
        variant: 'subtle',
        isClosable: true,
        status: 'success',
        position: 'top-right',
      });
    }
  };

  if (loading || isLoading) {
    return <Loading h="100%" />;
  }

  if (!signupToken || isError) return <LinkExpired />;

  return isLoggedInFromApi ? (
    <RedirectToPath path={DASHBOARD} keepQueryParams={false} />
  ) : (
    <Box>
      <Text fontSize="xl" mt={6}>
        {i18n.t('signup.emailPasswordSignup.title')}
      </Text>
      {validateSignupTokenData?.data.isEmailPassEnabled && (
        <Box w={{ base: '100%', lg: '50%' }} as="form" onSubmit={handleSubmit}>
          <PasswordField
            label={i18n.t('signup.emailPasswordSignup.passwordLabel')}
            value={password}
            helpText={i18n.t('signup.emailPasswordSignup.passwordHelpText')}
            handleValueChange={handlePasswordChange}
          />
          <PasswordStrengthBar score={result?.score || 0} />
          <PasswordField
            label={i18n.t('signup.emailPasswordSignup.confirmPasswordLabel')}
            value={confirmPassword}
            errorMessage={passwordMatchMessage}
            handleValueChange={handleConfirmPasswordChange}
          />
          <Button
            type="submit"
            isDisabled={password.length < 8}
            colorScheme="blue"
            mt={4}
            px={10}
          >
            {i18n.t('common.login')}
          </Button>
        </Box>
      )}
      {validateSignupTokenData?.data.isAzureSSOEnabled &&
        validateSignupTokenData?.data.isEmailPassEnabled && (
          <Box position="relative" py={6}>
            <Divider borderColor={'gray.400'} />
            <AbsoluteCenter bg="white" px="4" color={'keyops.gray'}>
              {i18n.t('common.or')}
            </AbsoluteCenter>
          </Box>
        )}
      {validateSignupTokenData?.data.isAzureSSOEnabled && (
        <Flex mt={4} justifyContent={'center'}>
          <Button
            variant={'outline'}
            colorScheme="blue"
            onClick={(e) => {
              e.preventDefault();
              handleAzureSignup(signupToken);
            }}
          >
            {i18n.t('common.loginWithAzure')}
          </Button>
        </Flex>
      )}
    </Box>
  );
};

export default EmailPasswordSignup;
