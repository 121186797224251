import { useQuery } from '@tanstack/react-query';

import { apiClient } from '../api/swagger-codegen-api-config';

export const useGetComparableEngagements = (
  engagementId: string | undefined
) => {
  return useQuery({
    queryKey: ['comparableEngagementsById', engagementId],
    queryFn: async () => {
      if (engagementId) {
        const response =
          await apiClient.surveyEngagementsApi.surveyEngagementControllerGetComparableEngagements(
            engagementId
          );
        return response.data;
      }
    },
  });
};
