import { useRef, useState } from 'react';
import { FiChevronLeft, FiChevronRight } from 'react-icons/fi';
import { useNavigate, useOutletContext, useParams } from 'react-router-dom';
import {
  Box,
  Button,
  Center,
  HStack,
  IconButton,
  Link,
  Progress,
  Select,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
} from '@chakra-ui/react';
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  PaginationState,
  Row,
  SortingState,
  useReactTable,
} from '@tanstack/react-table';

import ErrorComponent from '../../components/ErrorComponent';
import ColumnHeader from '../../components/TableComponents/ColumnHeader';
import { dateFormatter } from '../../utils/functions/common-utils';
import i18n from '../../utils/i18n';

import {
  AdBoardEngagementParticipantsDto,
  AdBoardOutletContextType,
} from './types';

const createColumns = () => {
  const columnHelper = createColumnHelper<AdBoardEngagementParticipantsDto>();
  return [
    columnHelper.accessor('user.firstName', {
      cell: (info) => (
        <Box
          display={'flex'}
          flexDir={'column'}
          justifyItems={'flex-start'}
          gap={1}
          data-testid="participant-name"
        >
          <Link
            href={'#'}
            color="keyops.blue"
            target="_blank"
            rel="noopener noreferrer"
          >
            {info.getValue()} {info.row.original.user?.lastName}
          </Link>
          <Text>
            Last active: {dateFormatter(info.row.original.updatedAt.toString())}
          </Text>
        </Box>
      ),
      header: ({ column }) => (
        <ColumnHeader headerText={'Name'} sorted={column.getIsSorted()} />
      ),
    }),
    columnHelper.accessor('progress.startedOn', {
      cell: (info) => {
        const finishedOn = info.getValue();
        return finishedOn ? (
          <Text>{dateFormatter(finishedOn.toString())}</Text> // Format the date if defined
        ) : null; // Handle undefined or null
      },
      header: ({ column }) => (
        <ColumnHeader headerText={'Started on'} sorted={column.getIsSorted()} />
      ),
    }),
    columnHelper.accessor('completionPercentage', {
      cell: (info) => (
        <HStack>
          <Progress value={info.getValue()} w={'100%'} borderRadius={8} />
          <Text>{info.getValue()}%</Text>
        </HStack>
      ),
      header: ({ column }) => (
        <Center>
          <ColumnHeader headerText={'Progress'} sorted={column.getIsSorted()} />
        </Center>
      ),
    }),
    columnHelper.accessor('progress.finishedOn', {
      cell: (info) => {
        const finishedOn = info.getValue();
        return finishedOn ? (
          <Center>{dateFormatter(finishedOn.toString())}</Center> // Format the date if defined
        ) : null; // Handle undefined or null
      },
      header: ({ column }) => (
        <Center>
          <ColumnHeader
            headerText={'Finished on'}
            sorted={column.getIsSorted()}
          />
        </Center>
      ),
    }),
  ];
};

export const AdBoardParticipants = () => {
  const { participantsData } = useOutletContext<AdBoardOutletContextType>();
  const navigate = useNavigate();
  const { adboardId } = useParams();

  const tableRef = useRef<HTMLDivElement | null>(null);

  const [sorting, setSorting] = useState<SortingState>([]);
  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });

  const columns = createColumns();
  const table = useReactTable({
    data: participantsData,
    columns,
    state: {
      sorting,
      pagination,
    },
    onSortingChange: setSorting,
    onPaginationChange: setPagination,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
  });

  const onRowClick = (
    e: React.MouseEvent<HTMLTableRowElement, MouseEvent>,
    row: Row<AdBoardEngagementParticipantsDto>
  ) => {
    e.preventDefault();
    navigate(`/adboard/${adboardId}/participants/${row.original.userId}`);
  };

  const tableContainerBgColor = useColorModeValue('white', 'gray.700');
  const tableTextColor = useColorModeValue('gray.700', 'white');
  const tableRowHoverBgColor = useColorModeValue('gray.100', 'gray.800');

  if (!participantsData) return <ErrorComponent />;

  return (
    <>
      <TableContainer
        ref={tableRef}
        mt={6}
        py={3}
        bg={tableContainerBgColor}
        borderWidth={'thin'}
        borderColor={'gray.200'}
        borderRadius={'lg'}
        overflowX={'auto'}
        position="relative"
      >
        <Table variant="unstyled">
          <Thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <Tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <Th
                    key={header.id}
                    cursor={'pointer'}
                    fontSize={'sm'}
                    onClick={header.column.getToggleSortingHandler()}
                    textTransform={'capitalize'}
                    color={tableTextColor}
                  >
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                  </Th>
                ))}
              </Tr>
            ))}
          </Thead>
          <Tbody>
            {table.getRowModel().rows?.length ? (
              table.getRowModel().rows.map((row) => (
                <Tr
                  key={row.id}
                  data-testid={'data-row'}
                  cursor={'pointer'}
                  fontSize={'sm'}
                  _hover={{ bg: tableRowHoverBgColor }}
                  onClick={(e) => onRowClick(e, row)}
                >
                  {row.getVisibleCells().map((cell) => (
                    <Td key={cell.id} color={tableTextColor} py={2.5}>
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </Td>
                  ))}
                </Tr>
              ))
            ) : (
              <Tr>
                <Td
                  colSpan={columns.length}
                  textAlign={'center'}
                  color={'blue.700'}
                  py={20}
                >
                  {i18n.t('adBoardDetails.engagement.noData')}
                </Td>
              </Tr>
            )}
          </Tbody>
        </Table>
      </TableContainer>
      {table.getRowModel().rows?.length > 0 && (
        <HStack gap={2} mt={16} justifyContent={'center'}>
          <IconButton
            variant={'ghost'}
            colorScheme="blue"
            aria-label="Previous page"
            icon={<FiChevronLeft fontSize={22} />}
            onClick={() => table.previousPage()}
            isDisabled={!table.getCanPreviousPage()}
          />
          {table.getPageCount() > 1 &&
            table.getState().pagination.pageIndex + 1 ===
              table.getPageCount() && (
              <Button
                variant={'ghost'}
                onClick={() =>
                  table.setPageIndex(table.getState().pagination.pageIndex - 1)
                }
              >
                {table.getState().pagination.pageIndex}
              </Button>
            )}
          <Button
            variant={'outline'}
            colorScheme={'blue'}
            onClick={() => table.setPageIndex(0)}
          >
            {table.getState().pagination.pageIndex + 1}
          </Button>
          {table.getState().pagination.pageIndex + 1 < table.getPageCount() && (
            <Button
              variant={'ghost'}
              onClick={() =>
                table.setPageIndex(table.getState().pagination.pageIndex + 1)
              }
            >
              {table.getState().pagination.pageIndex + 2}
            </Button>
          )}
          <IconButton
            variant={'ghost'}
            colorScheme="blue"
            aria-label="Next page"
            icon={<FiChevronRight fontSize={22} />}
            onClick={() => table.nextPage()}
            isDisabled={!table.getCanNextPage()}
          />
          <Select
            variant="outline"
            maxW={140}
            value={table.getState().pagination.pageSize}
            onChange={(e) => {
              table.setPageSize(Number(e.target.value));
            }}
            aria-label="show"
          >
            {[10, 20, 30].map((pageSize) => (
              <option key={pageSize} value={pageSize}>
                Show {pageSize}
              </option>
            ))}
          </Select>
        </HStack>
      )}
    </>
  );
};
