import React, { memo } from 'react';
import { Card, CardBody, CardHeader, Heading } from '@chakra-ui/react';
import { SurveyEngagementDetailsDto } from '@keyops-cep/api-client';

import MarkdownDisplay from '../../../components/MarkdownDisplay';
import i18n from '../../../utils/i18n';

export type AnalysisConclusionProps = {
  engagementDetails: SurveyEngagementDetailsDto;
};

const AnalysisConclusion = ({
  engagementDetails,
}: AnalysisConclusionProps): JSX.Element => {
  if (!engagementDetails || !engagementDetails.analysis) {
    return <></>;
  }
  return (
    <Card mt={4} pt={4}>
      <CardHeader py={0}>
        <Heading as={'h1'} size="md">
          {i18n.t('engagementDetails.conclusion.heading')}
        </Heading>
      </CardHeader>
      <CardBody style={{ whiteSpace: 'pre-wrap' }}>
        <MarkdownDisplay source={engagementDetails.analysis?.conclusion} />
      </CardBody>
    </Card>
  );
};

export default memo(AnalysisConclusion);
