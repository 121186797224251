import { useQuery } from '@tanstack/react-query';

import { getResultDataToExport } from '../api/ad-board.functions';
import i18n from '../utils/i18n';

export const useAdBoardResultsExport = (adboardId?: string) => {
  return useQuery({
    queryKey: ['adBoardResultsExport', adboardId],
    queryFn: async () => {
      if (!adboardId) {
        throw new Error(i18n.t('adBoardDetails.missingAdBoardId'));
      }
      const res = await getResultDataToExport(adboardId); // Assume this is an API call returning a Blob
      return res;
    },
    enabled: !!adboardId, // Only run query if adboardId is present
    retry: false,
  });
};
