import { InfoIcon } from '@chakra-ui/icons';
import { Box, Heading, Text } from '@chakra-ui/react';

import i18n from '../../../utils/i18n';

export const InsightsEmptyState = () => {
  return (
    <Box
      display={'flex'}
      alignItems={'center'}
      bg={'blue.50'}
      borderRadius={'lg'}
      p={4}
      gap={3}
    >
      <InfoIcon fontSize={'lg'} color={'keyops.blue'} />
      <Box display={'flex'} flexDirection={'column'}>
        <Heading fontSize={'md'} lineHeight={6}>
          {i18n.t('adBoardDetails.executiveSummary.emptyInsightsTitle')}
        </Heading>
        <Text>
          {i18n.t('adBoardDetails.executiveSummary.emptyInsightsDescription')}
        </Text>
      </Box>
    </Box>
  );
};
