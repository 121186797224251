export function formatDateMonthYear(endDate: string) {
  // Ex: formats "2024-03-07T14:00:00.000Z" -> Mar 2024
  const date = new Date(endDate);
  // Use Intl.DateTimeFormat to format the date
  const formattedDate = new Intl.DateTimeFormat('en-US', {
    month: 'short',
    year: 'numeric',
  }).format(date);
  return formattedDate;
}

export const getVideoTime = (seconds: number): string => {
  if (seconds === 0) return '0:00';

  const dateString = new Date(seconds * 1000).toISOString();
  if (seconds < 600) {
    //if it's less than 10 mins, we drop the hour part and first minute, and only show M:SS
    return dateString.substring(15, 19);
  }
  if (seconds < 3600) {
    //if it's less than an hour, we drop the hour part, and only show MM:SS
    return dateString.substring(14, 19);
  }
  if (seconds < 36000) {
    //if it's less than 10 hours, we drop the leading hour digit, and only show H:MM:SS
    return dateString.substring(12, 19);
  }
  //Otherwise show HH:MM:SS
  return dateString.substring(11, 19);
};
