// Auth Routes
export const ROOT = `/`;
export const LOGIN = `/login`;
export const FORGOT_PASSWORD = `/forgot-password`;
export const RESET_PASSWORD = `/reset-password/:resetPasswordToken`;
export const LOGOUT = `/logout`;
export const SIGNUP = `/signup/:signupToken`;

export const ADMIN_LOGIN_REDIRECT = `/admin-login/:redirectUrl`;

// Main Pages Routes
export const DASHBOARD = `/dashboard`;

// Engagements Pages Routes
export const ENGAGEMENTS = `/engagements`;
export const ENGAGEMENT_DETAILS = `${ENGAGEMENTS}/:engagementId`;
export const ENGAGEMENT_RESULTS_TAB = `results`;
export const ENGAGEMENT_COMPARATIVE_ANALYSIS_TAB = `compare`;
export const ENGAGEMENT_COMPARATIVE_ANALYSIS_OVERVIEW = `:comparableEngagementsId/overview`;
export const ENGAGEMENT_COMPARATIVE_ANALYSIS_QUESTION_COMPARISON = `:comparableEngagementsId/question-comparison`;

// Ad-Board Pages Routes
export const ADBOARD = `/adboard`;
export const ADBOARD_DETAILS = `${ADBOARD}/:adboardId`;
export const EXECUTIVE_SUMMARY_TAB = `executive-summary`;
export const ENGAGEMENT_TAB = `engagement`;
export const PARTICIPANTS_TAB = `participants`;
export const ADBOARD_EXECUTIVE_SUMMARY = `${ADBOARD}/:adboardId/${EXECUTIVE_SUMMARY_TAB}`;
export const ADBOARD_ENGAGEMENT = `${ADBOARD}/:adboardId/${ENGAGEMENT_TAB}`;
export const ADBOARD_PARTICIPANTS = `${ADBOARD}/:adboardId/${PARTICIPANTS_TAB}`;

// Test Pages Routes
export const TEST_PAGE = `/test-page`;
export const TEST_PAGE_2 = `${TEST_PAGE}/test-page-2`;
