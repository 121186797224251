import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Button, Center, Text, Tooltip } from '@chakra-ui/react';
import { AllEngagementsDto, FeatureFlagNameEnum } from '@keyops-cep/api-client';
import { createColumnHelper, FilterFn } from '@tanstack/react-table';

import ColumnHeader from '../../../components/TableComponents/ColumnHeader';
import useBEFeatureFlag from '../../../hooks/useBEFeatureFlag';
import { ANALYTICS_EVENT_NAME } from '../../../utils/constants/analytics-constants';
import {
  // ENGAGEMENT_BADGE_COLOR_SCHEME,
  ENGAGEMENT_STATUSES,
  // EngagementStatusValues,
} from '../../../utils/dto/engagement.dto';
import { dateFormatter, getUrl } from '../../../utils/functions/common-utils';
import i18n from '../../../utils/i18n';
import {
  ADBOARD_DETAILS,
  ENGAGEMENT_COMPARATIVE_ANALYSIS_TAB,
  ENGAGEMENT_DETAILS,
  ENGAGEMENT_RESULTS_TAB,
} from '../../../utils/internal-routes.const';

// Build table columns logic
export interface EngagementsTableColumnsDto extends AllEngagementsDto {
  participants?: string;
  action?: string;
}

const columnHelper = createColumnHelper<EngagementsTableColumnsDto>();

const MAX_PROJECT_NAME_WIDTH = 40;

const engagementTypeMap = {
  AdBoardEngagement: 'KoLab',
  SurveyEngagement: 'Survey',
};

// TODO: update when status handling is revised
// const StatusBadge = ({ status }: { status: EngagementStatusValues }) => {
//   const isInsightsAvailable = status === ENGAGEMENT_STATUSES.INSIGHTS_AVAILABLE;
//   return (
//     <>
//       {isInsightsAvailable && (
//         <>
//           <Badge
//             colorScheme={
//               ENGAGEMENT_BADGE_COLOR_SCHEME[
//                 ENGAGEMENT_STATUSES.RAW_DATA_AVAILABLE
//               ]
//             }
//           >
//             {ENGAGEMENT_STATUSES.RAW_DATA_AVAILABLE}
//           </Badge>
//           <br />
//         </>
//       )}
//       <Badge
//         colorScheme={ENGAGEMENT_BADGE_COLOR_SCHEME[status]}
//         mt={isInsightsAvailable ? 1 : 0}
//       >
//         {status}
//       </Badge>
//     </>
//   );
// };

const filterTitleColumn: FilterFn<EngagementsTableColumnsDto> = (
  rows,
  id,
  filterValue
) => {
  if (!filterValue) return true;

  return (
    !!rows.original['name']
      ?.toLowerCase()
      ?.includes(filterValue.toLowerCase()) ||
    !!rows.original['displayTitle']
      ?.toLowerCase()
      ?.includes(filterValue.toLowerCase())
  );
};

const useEngagementsTableColumns = (tenantId: string) => {
  const navigate = useNavigate();
  const { data: isComparisonFlagActive } = useBEFeatureFlag(
    tenantId,
    FeatureFlagNameEnum.CustomerShowComparison
  );

  const columns = React.useMemo(
    () => [
      columnHelper.accessor('name', {
        header: ({ column }) => (
          <ColumnHeader headerText={'Title'} sorted={column.getIsSorted()} />
        ),
        filterFn: filterTitleColumn,
        cell: (props) => {
          const displayTitle = props.row.original.displayTitle;
          const shortDisplayTitle = displayTitle
            ? displayTitle.length > MAX_PROJECT_NAME_WIDTH
              ? displayTitle.substring(0, MAX_PROJECT_NAME_WIDTH) + '...'
              : displayTitle
            : '';

          return (
            <Tooltip
              label={displayTitle}
              isDisabled={displayTitle === shortDisplayTitle}
              bg="white"
              color="black"
              maxW="none"
              fontSize="xs"
              p={1}
            >
              <Box maxWidth={'20vw'}>
                <Text isTruncated>{props.getValue()}</Text>
                <Text isTruncated fontSize={'12px'} mt={1}>
                  {shortDisplayTitle}
                </Text>
              </Box>
            </Tooltip>
          );
        },
      }),
      // TODO: update when status handling is revised
      // columnHelper.accessor('status', {
      //   header: ({ column }) => (
      //     <ColumnHeader headerText={'Status'} sorted={column.getIsSorted()} />
      //   ),
      //   cell: (props) => <StatusBadge status={props.getValue()} />,
      // }),
      columnHelper.accessor('discriminator', {
        header: ({ column }) => (
          <ColumnHeader
            headerText={i18n.t('engagements.type')}
            sorted={column.getIsSorted()}
          />
        ),
        cell: (props) => (
          <Text pr={10} textTransform={'capitalize'}>
            {engagementTypeMap[props.getValue()] || props.getValue()}
          </Text>
        ),
      }),
      columnHelper.accessor('startDate', {
        header: ({ column }) => (
          <ColumnHeader
            headerText={'Start Date'}
            sorted={column.getIsSorted()}
          />
        ),
        cell: (props) => dateFormatter(props.getValue()),
      }),
      columnHelper.accessor('endDate', {
        header: ({ column }) => (
          <ColumnHeader headerText={'Closed'} sorted={column.getIsSorted()} />
        ),
        cell: (props) => dateFormatter(props.getValue()),
      }),
      columnHelper.accessor('participants', {
        header: () => (
          <Text align="right" pr={10} textTransform={'capitalize'}>
            {i18n.t('engagements.participants')}
          </Text>
        ),
        enableSorting: false,
        cell: (props) => (
          <Text align="right" pr={10}>
            {props.row.original.responseCount}/
            {props.row.original.responseCountTarget}
          </Text>
        ),
      }),
      columnHelper.accessor('action', {
        header: () => <Center>Action</Center>,
        enableSorting: false,
        cell: (props) => {
          if (
            props.row.original.resultsStatus ===
              ENGAGEMENT_STATUSES.RAW_DATA_AVAILABLE ||
            props.row.original.resultsStatus ===
              ENGAGEMENT_STATUSES.INSIGHTS_AVAILABLE
          )
            return (
              <Center>
                <Button
                  colorScheme="blue"
                  variant="ghost"
                  _hover={{ textDecoration: 'underline' }}
                  fontWeight={500}
                  onClick={(event) => {
                    event.stopPropagation(); // Stop event propagation to engagementTable RowClick
                    if (
                      props.row.original.discriminator === 'AdBoardEngagement'
                    ) {
                      navigate(
                        getUrl(ADBOARD_DETAILS, {
                          adboardId: props.row.original.id,
                        })
                      );
                    } else {
                      window.analytics.track(
                        isComparisonFlagActive
                          ? ANALYTICS_EVENT_NAME.DASHBOARD
                              .ENGAGEMENT_LIST_COMPARE_BUTTON_CLICK
                          : ANALYTICS_EVENT_NAME.DASHBOARD
                              .ENGAGEMENT_LIST_VIEW_BUTTON_CLICK,
                        {
                          engagementId: props.row.original.id,
                          name: props.row.original.name,
                          status: props.row.original.resultsStatus,
                        }
                      );
                      navigate(
                        `${getUrl(ENGAGEMENT_DETAILS, {
                          engagementId: props.row.original.id,
                        })}/${
                          isComparisonFlagActive
                            ? ENGAGEMENT_COMPARATIVE_ANALYSIS_TAB
                            : ENGAGEMENT_RESULTS_TAB
                        }`
                      );
                    }
                  }}
                >
                  {props.row.original.discriminator === 'AdBoardEngagement' ? (
                    i18n.t('common.view')
                  ) : (
                    <>
                      {isComparisonFlagActive
                        ? i18n.t('common.compare')
                        : i18n.t('common.view')}
                    </>
                  )}
                </Button>
              </Center>
            );
          return <></>;
        },
      }),
    ],
    [navigate, isComparisonFlagActive]
  );

  return columns;
};

export default useEngagementsTableColumns;
