import { Box, RadioProps, useRadio } from '@chakra-ui/react';

const RadioCard = (props: RadioProps) => {
  const { getInputProps, getRadioProps } = useRadio(props);

  const input = getInputProps();
  const checkbox = getRadioProps();

  return (
    <Box as="label">
      <input {...input} />
      <Box
        {...checkbox}
        cursor="pointer"
        borderWidth="1px"
        borderRadius="md"
        _checked={{
          bg: 'keyops.blue',
          color: 'white',
          borderColor: 'keyops.blue',
        }}
        px={6}
        py={1.5}
      >
        {props.children}
      </Box>
    </Box>
  );
};

export { RadioCard };
