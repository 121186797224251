import { FiChevronLeft } from 'react-icons/fi';
import { useNavigate } from 'react-router-dom';

import { KeyOpsLinkButton } from '../../../components/ButtonComponents';
import i18n from '../../../utils/i18n';
import { DASHBOARD } from '../../../utils/internal-routes.const';

const BackToHome = () => {
  const navigate = useNavigate();
  const navigateToDashboard = () => navigate(DASHBOARD);

  return (
    <KeyOpsLinkButton
      _hover={{ textDecoration: 'none' }}
      leftIcon={<FiChevronLeft data-testid="FiChevronLeft" fontSize={22} />}
      onClick={navigateToDashboard}
    >
      {i18n.t('common.backToHome')}
    </KeyOpsLinkButton>
  );
};

export default BackToHome;
