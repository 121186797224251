// External Dependencies
import { useEffect, useState } from 'react';
import { ChevronDownIcon } from '@chakra-ui/icons';
import {
  Box,
  Button,
  Flex,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Tooltip,
  useToast,
} from '@chakra-ui/react';
import {
  EngagementFile,
  SurveyEngagementDetailsDto,
} from '@keyops-cep/api-client';

import { apiClient } from '../../../api/swagger-codegen-api-config';
// Internal Dependencies
import { ANALYTICS_EVENT_NAME } from '../../../utils/constants/analytics-constants';
import { exportSurveyResult } from '../../../utils/functions/export-survey';
import i18n from '../../../utils/i18n';

function formatFileName(fileName: string, charLimit: number) {
  // Check if the file name length is within the limit
  if (fileName.length <= charLimit) {
    return fileName;
  }

  const fileExtension = fileName.substring(fileName.lastIndexOf('.'));
  const baseName = fileName.substring(0, fileName.lastIndexOf('.'));

  // Calculate the maximum length for the start part
  const maxStartPartLength = charLimit - fileExtension.length - 4 - 3; // Subtracting for ellipsis, space, and last 4 chars

  if (baseName.length > maxStartPartLength) {
    const startPart = baseName.substring(0, maxStartPartLength);
    const endPart = baseName.slice(-4);
    return `${startPart}... ${endPart}${fileExtension}`;
  }

  return fileName; // Return the original fileName if it's shorter than the char limit
}

const CustomMenuButtonContent = () => {
  return (
    <Flex align="center">
      <Box mr={4}>Download</Box>
      <ChevronDownIcon />
    </Flex>
  );
};

const DownloadButton = ({
  engagementData,
}: {
  engagementData?: SurveyEngagementDetailsDto;
}): JSX.Element => {
  const [engagementFiles, setEngagementFiles] = useState<EngagementFile[]>([]);
  const toast = useToast();

  const fetchEngagementFiles = async (engagementId: string) => {
    const response =
      await apiClient.engagementsApi.engagementControllerGetEngagementFiles(
        engagementId
      );
    setEngagementFiles(response.data);
  };

  const getFileDownloadLink = async (engagementFileId: string) => {
    const response =
      await apiClient.engagementFilesApi.engagementFileControllerGetDownloadLink(
        engagementFileId
      );
    return response.data;
  };

  useEffect(() => {
    if (engagementData) {
      fetchEngagementFiles(engagementData.id);
    }
  }, [engagementData]);

  return (
    <Menu placement="bottom-end">
      <MenuButton
        variant={'solid'}
        colorScheme={'blue'}
        backgroundColor={'keyops.blue'}
        color={'white'}
        _hover={{
          bgColor: '#285ea7',
          color: 'white',
        }}
        as={Button}
        px={2}
        rightIcon={<CustomMenuButtonContent />}
      />
      <MenuList>
        <MenuItem
          onClick={() => {
            window.analytics.track(
              ANALYTICS_EVENT_NAME.ENGAGEMENT_DETAILS.EXPORT_DATA,
              {
                engagementId: engagementData?.id,
                name: engagementData?.name,
                status: engagementData?.resultsStatus,
              }
            );
            exportSurveyResult(
              engagementData as SurveyEngagementDetailsDto,
              `${engagementData?.name}-raw-data.csv`,
              'text/csv;charset=utf-8;'
            );
          }}
        >
          {i18n.t('common.exportData')}
        </MenuItem>
        {engagementFiles.map((engagementFile) => {
          return (
            <Tooltip
              key={engagementFile.id}
              label={engagementFile.fileName}
              placement="right"
            >
              <MenuItem
                onClick={() => {
                  getFileDownloadLink(engagementFile.id).then(
                    (val) => {
                      const link = document.createElement('a');
                      link.href = val.downloadLink;
                      link.download = engagementFile.fileName;
                      document.body.appendChild(link);
                      link.click();
                      document.body.removeChild(link);
                    },
                    (_) =>
                      toast({
                        title: 'Something went wrong.',
                        description: `Failed to get the download link for ${engagementFile.fileName}`,
                        status: 'error',
                        duration: 3000,
                        position: 'top-right',
                        isClosable: true,
                      })
                  );
                }}
              >
                {formatFileName(engagementFile.fileName, 40)}
              </MenuItem>
            </Tooltip>
          );
        })}
      </MenuList>
    </Menu>
  );
};

export default DownloadButton;
