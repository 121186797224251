import React, { useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { SearchIcon } from '@chakra-ui/icons';
import {
  Box,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
} from '@chakra-ui/react';
import { AllEngagementsDto } from '@keyops-cep/api-client';
import {
  ColumnFiltersState,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  Row,
  SortingState,
  useReactTable,
} from '@tanstack/react-table';
import { debounce } from 'lodash';

import SearchInputComponent from '../../../components/SearchInputComponent';
import { ANALYTICS_EVENT_NAME } from '../../../utils/constants/analytics-constants';
import { ENGAGEMENT_STATUSES } from '../../../utils/dto/engagement.dto';
import { getUrl } from '../../../utils/functions/common-utils';
import i18n from '../../../utils/i18n';
import {
  ADBOARD_DETAILS,
  ENGAGEMENT_DETAILS,
  ENGAGEMENT_RESULTS_TAB,
} from '../../../utils/internal-routes.const';

import useEngagementsTableColumns from './useEngagementsTableColumns';
interface EngagementsTableProps {
  data: AllEngagementsDto[];
}

const EngagementsTable = ({ data }: EngagementsTableProps) => {
  const engagementsTableColumns = useEngagementsTableColumns(data[0].tenantId);
  const [sorting, setSorting] = React.useState<SortingState>([
    { id: 'endDate', desc: true },
  ]);
  const [searchTerm, setSearchTerm] = React.useState<string>('');
  const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>(
    []
  );
  const navigate = useNavigate();
  const table = useReactTable({
    data,
    columns: engagementsTableColumns,
    getCoreRowModel: getCoreRowModel(),
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
    onColumnFiltersChange: setColumnFilters,
    getFilteredRowModel: getFilteredRowModel(),
    state: {
      sorting,
      columnFilters,
    },
  });

  const tableTextColor = useColorModeValue('gray.700', 'white');
  const tableRowHoverBgColor = useColorModeValue('gray.100', 'gray.800');

  const searchCall = useCallback(
    (value: string) => {
      table.getColumn('name')?.setFilterValue(value);
      window.analytics.track(
        ANALYTICS_EVENT_NAME.DASHBOARD.ENGAGEMENT_LIST_SEARCH,
        {
          searchTerm: value,
        }
      );
    },
    [table]
  );

  const debouncedSearch = useMemo(() => {
    return debounce(searchCall, 500);
  }, [searchCall]);

  const onRowClick = (row: Row<AllEngagementsDto>) => {
    if (
      row.original.resultsStatus === ENGAGEMENT_STATUSES.RAW_DATA_AVAILABLE ||
      row.original.resultsStatus === ENGAGEMENT_STATUSES.INSIGHTS_AVAILABLE
    ) {
      window.analytics?.track(
        ANALYTICS_EVENT_NAME.DASHBOARD.ENGAGEMENT_LIST_VIEW_BUTTON_CLICK,
        {
          engagementId: row.original.id,
          name: row.original.name,
          status: row.original.resultsStatus,
        }
      );
      if (row.original.discriminator === 'AdBoardEngagement')
        navigate(
          getUrl(ADBOARD_DETAILS, {
            adboardId: row.original.id,
          })
        );
      else
        navigate(
          `${getUrl(ENGAGEMENT_DETAILS, {
            engagementId: row.original.id,
          })}/${ENGAGEMENT_RESULTS_TAB}`
        );
    }
  };

  return (
    <>
      <Box pt={6} w={{ base: '100%', md: '50%' }}>
        <SearchInputComponent
          placeholder={
            i18n.t('engagements.tableSection.searchPlaceholder') || ''
          }
          value={searchTerm}
          onChange={(event) => {
            setSearchTerm(event.target.value);
            debouncedSearch(event.target.value);
          }}
        />
      </Box>
      <TableContainer
        mt={6}
        py={3}
        pl={3}
        bg={useColorModeValue('white', 'gray.700')}
        borderWidth={'thin'}
        borderColor={'gray.200'}
        borderRadius={'lg'}
        overflowX={'auto'}
        position="relative"
      >
        <Table variant="unstyled">
          <Thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <Tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                  const isActionColumnHeader = header.id === 'action';
                  return (
                    <Th
                      key={header.id}
                      cursor={'pointer'}
                      fontSize={'sm'}
                      onClick={header.column.getToggleSortingHandler()}
                      textTransform={'capitalize'}
                      color={tableTextColor}
                      width={isActionColumnHeader ? 'auto' : undefined}
                      position={isActionColumnHeader ? 'sticky' : undefined}
                      right={isActionColumnHeader ? 0 : undefined}
                      zIndex={isActionColumnHeader ? 1 : undefined}
                      backgroundColor={
                        isActionColumnHeader ? 'white' : undefined
                      }
                    >
                      {header.isPlaceholder
                        ? null
                        : flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                    </Th>
                  );
                })}
              </Tr>
            ))}
          </Thead>
          <Tbody>
            {table.getRowModel().rows?.length ? (
              table.getRowModel().rows.map((row) => (
                <Tr
                  key={row.id}
                  data-testid={'data-row'}
                  cursor={'pointer'}
                  data-state={row.getIsSelected() && 'selected'}
                  fontSize={'sm'}
                  _hover={{ bg: tableRowHoverBgColor }}
                  onClick={() => onRowClick(row)}
                >
                  {row.getVisibleCells().map((cell) => {
                    const isActionColumnCell = cell.column.id === 'action';
                    return (
                      <Td
                        key={cell.id}
                        color={tableTextColor}
                        py={2.5}
                        width={isActionColumnCell ? 'auto' : undefined}
                        position={isActionColumnCell ? 'sticky' : undefined}
                        right={isActionColumnCell ? 0 : undefined}
                        zIndex={isActionColumnCell ? 1 : undefined}
                        backgroundColor={
                          isActionColumnCell ? 'white' : undefined
                        }
                      >
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}
                      </Td>
                    );
                  })}
                </Tr>
              ))
            ) : (
              <Tr>
                <Td
                  colSpan={engagementsTableColumns.length}
                  textAlign={'center'}
                  color={'blue.700'}
                  py={20}
                >
                  {columnFilters.length ? (
                    <>
                      <SearchIcon color="blue.200" boxSize={9} />
                      <Text mt={3} fontSize={'sm'} lineHeight={6}>
                        {i18n.t('engagements.tableSection.noSearchResults', {
                          searchQuery: table
                            .getColumn('name')
                            ?.getFilterValue(),
                        })}
                      </Text>
                      <Text fontSize={'sm'} lineHeight={6}>
                        {i18n.t('engagements.tableSection.tryAgain')}
                      </Text>
                    </>
                  ) : (
                    i18n.t('engagements.tableSection.noResults')
                  )}
                </Td>
              </Tr>
            )}
          </Tbody>
        </Table>
      </TableContainer>
    </>
  );
};

export default EngagementsTable;
