import { useMemo, useState } from 'react';
import { FiChevronRight } from 'react-icons/fi';
import ReactPlayer from 'react-player';
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Card,
  CardBody,
  Center,
  Link,
  List,
  Text,
} from '@chakra-ui/react';

import Loading from '../../../components/Loading';
import i18n from '../../../utils/i18n';
import { AttachmentsAdBoardSectionDto, VideoAdBoardSectionDto } from '../types';

type ActivityPreviewProps = {
  selectedActivity: AttachmentsAdBoardSectionDto | VideoAdBoardSectionDto;
};

const getTitle = (activityType: string) => {
  switch (activityType) {
    case 'video':
      return i18n.t('adBoardDetails.engagement.previewTitle', {
        activityType: activityType,
      });
    case 'attachment':
      return i18n.t('adBoardDetails.engagement.previewTitle', {
        activityType: 'documents',
      });
    default:
      return null;
  }
};

export const ActivityPreview = ({ selectedActivity }: ActivityPreviewProps) => {
  const [isLoading, setIsLoading] = useState(false);

  const content = useMemo(() => {
    switch (selectedActivity?.type) {
      case 'video':
        return (
          <Center w={'100%'}>
            <ReactPlayer
              url={selectedActivity.content.video?.link}
              onReady={() => setIsLoading(false)}
              onError={() => setIsLoading(true)}
              controls
              style={{ marginTop: 12 }}
            />
          </Center>
        );
      case 'attachment':
        return (
          <List spacing={2} mt={4}>
            {selectedActivity.content.map((attachmentContent, i) => (
              <Card key={`${attachmentContent.id}-${i}`} variant="outline">
                <CardBody
                  as={Link}
                  href={attachmentContent.attachment.link}
                  target={'_blank'}
                  display={'flex'}
                  alignItems={'center'}
                  justifyContent={'space-between'}
                  cursor={'pointer'}
                  style={{ textDecoration: 'none' }}
                  data-testid={`attachments-link-${attachmentContent.id}`}
                >
                  <Text>{attachmentContent.attachment.title}</Text>
                  <FiChevronRight fontSize={22} />
                </CardBody>
              </Card>
            ))}
          </List>
        );
      default:
        return null;
    }
  }, [selectedActivity.content, selectedActivity?.type]);

  if (!selectedActivity) return null;

  return (
    <Accordion mt={8} allowMultiple>
      <AccordionItem>
        <h2>
          <AccordionButton pr={2.5}>
            <Box as="span" flex="1" textAlign="left">
              {getTitle(selectedActivity.type)}
            </Box>
            <AccordionIcon />
          </AccordionButton>
        </h2>
        <AccordionPanel>
          {isLoading ? <Loading /> : <Box w={'100%'}>{content}</Box>}
        </AccordionPanel>
      </AccordionItem>
    </Accordion>
  );
};
