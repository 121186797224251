import jwt_decode from 'jwt-decode';
import { forOwn } from 'lodash';

export const parseJwt = (
  token: string | null
): {
  exp: number;
  role: string;
} | null => {
  try {
    if (!token) return null;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const parsedData = jwt_decode(token) as any;
    return { ...parsedData, exp: parsedData.exp, role: parsedData.role };
  } catch (e) {
    console.log(e);
    return null;
  }
};

export const dateFormatter = (
  timestamp?: string,
  includeTime?: boolean
): string | null => {
  if (!timestamp) {
    return null;
  }
  return new Date(timestamp).toLocaleString(['en-CA'], {
    formatMatcher: 'best fit',
    dateStyle: 'medium',
    timeStyle: includeTime ? 'short' : undefined,
  });
};

export const getUrl = (
  route: string,
  params: Record<string, string> = {}
): string => {
  forOwn(params, (val, key) => {
    route = route.replace(`:${key}`, val);
  });
  return route;
};

// Used in Engage AI Chat to display the time when an assistant message was sent
export const getMinutesAgoFromString = (sentDateString: string): string => {
  const sentDate = new Date(sentDateString);
  const now = new Date();
  const diffMilliseconds = now.getTime() - sentDate.getTime();

  // First, calculate total difference in days, hours, and minutes
  const diffDays = Math.floor(diffMilliseconds / (3600000 * 24)); // total days
  const diffMinutes = Math.floor((diffMilliseconds % 3600000) / 60000); // remainder in minutes
  const diffHours = Math.floor(diffMilliseconds / 3600000); // total hours
  const diffWeeks = Math.floor(diffDays / 7);

  // Calculate the remaining hours after accounting for full days
  const remainingHours = diffHours % 24;

  if (diffMinutes === 0 && remainingHours === 0 && diffDays === 0) {
    return `Just now.`;
  }

  // Check if the message was sent the same day
  if (diffDays < 1) {
    if (sentDate.toDateString() === now.toDateString()) {
      const hours = sentDate.getHours();
      const minutes = sentDate.getMinutes();
      const formattedTime =
        (hours % 12 || 12) +
        ':' +
        (minutes < 10 ? '0' : '') +
        minutes +
        ' ' +
        (hours < 12 ? 'AM.' : 'PM.');
      return formattedTime;
    }
    return `${diffHours}h ago.`;
  }
  // Check if the message was sent more than a day ago but less than a week ago
  else if (diffDays < 7) {
    return `${diffDays}d ago.`;
  }
  // If the message was sent a week or more ago
  return `${diffWeeks}w ago.`;
};

export const capitalize = (str: string) =>
  str.charAt(0).toUpperCase() + str.slice(1);

export const calculatePercentage = (count: number, total: number) =>
  Math.round((100 * count) / total);

export const timeFormatter = (timestamp?: string): string | null => {
  if (!timestamp) {
    return null;
  }

  return new Date(timestamp)
    .toLocaleTimeString('en-CA', {
      hour: 'numeric',
      minute: 'numeric',
      hour12: true, // Ensures AM/PM format
    })
    .replace(/\.\s?/g, '');
};
