import { useQuery } from '@tanstack/react-query';

import { apiClient } from '../api/swagger-codegen-api-config';

export const useAdBoardData = ({
  adBoardId,
}: {
  adBoardId: string | undefined;
}) => {
  return useQuery({
    queryKey: ['use-ad-board-data', adBoardId],
    queryFn: async () => {
      if (adBoardId) {
        const response =
          await apiClient.adBoardEngagementsApi.adBoardEngagementControllerGetAdBoardEngagementDetails(
            adBoardId
          );
        return response.data;
      }
    },
    enabled: !!adBoardId, // Disable query if adBoardId is not provided
  });
};
