import React from 'react';
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
} from 'react-router-dom';

import PublicRouteLayout from './components/PublicRouteLayout';
import PrivateRoutes from './HOCs/auth/PrivateRoutes';
import PublicRoutes from './HOCs/auth/PublicRoutes';
import Layout from './HOCs/Layout';
import AdBoardDetails from './pages/AdBoardDetails';
import AdBoardDetailsWrapper from './pages/AdBoardDetails/AdBoardDetailsWrapper';
import { AdBoardEngagements } from './pages/AdBoardDetails/AdBoardEngagements';
import { AdBoardExecutiveSummary } from './pages/AdBoardDetails/AdBoardExecutiveSummary';
import { AdBoardIndividualParticipant } from './pages/AdBoardDetails/AdBoardIndividualParticipant';
import { AdBoardParticipants } from './pages/AdBoardDetails/AdBoardParticipants';
import Dashboard from './pages/Dashboard';
import EngagementDetails from './pages/EngagementDetails';
import ComparativeAnalysis from './pages/EngagementDetails/pages/Compare';
import ComparisonOverview from './pages/EngagementDetails/pages/Compare/Overview';
import QuestionComparison from './pages/EngagementDetails/pages/Compare/QuestionComparison';
import EngagementDetailsResults from './pages/EngagementDetails/pages/Results';
import Engagements from './pages/Engagements';
import Login from './pages/Login';
import AdminLogin from './pages/Login/admin-login';
import ForgotPassword from './pages/Login/ForgotPassword';
import Logout from './pages/Login/logout';
import ResetPassword from './pages/Login/ResetPassword';
import NotFound from './pages/NotFound';
import Signup from './pages/Signup';
import * as ROUTES from './utils/internal-routes.const';
import App from './App';

const router = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route element={<App />}>
        {/* private routes goes here */}
        <Route element={<PrivateRoutes />}>
          <Route path="/" element={<Layout />}>
            <Route path={ROUTES.DASHBOARD} element={<Dashboard />} />
            <Route
              path={ROUTES.ENGAGEMENT_DETAILS}
              element={<EngagementDetails />}
            >
              <Route index element={<EngagementDetailsResults />} />
              <Route
                index
                path={ROUTES.ENGAGEMENT_RESULTS_TAB}
                element={<EngagementDetailsResults />}
              />
              <Route
                path={ROUTES.ENGAGEMENT_COMPARATIVE_ANALYSIS_TAB}
                element={<ComparativeAnalysis />}
              >
                <Route
                  path={ROUTES.ENGAGEMENT_COMPARATIVE_ANALYSIS_OVERVIEW}
                  element={<ComparisonOverview />}
                />
                <Route
                  path={
                    ROUTES.ENGAGEMENT_COMPARATIVE_ANALYSIS_QUESTION_COMPARISON
                  }
                  element={<QuestionComparison />}
                />
              </Route>
            </Route>
            <Route element={<AdBoardDetailsWrapper />}>
              <Route path={ROUTES.ADBOARD_DETAILS} element={<AdBoardDetails />}>
                <Route
                  index
                  path={ROUTES.EXECUTIVE_SUMMARY_TAB}
                  element={<AdBoardExecutiveSummary />}
                />
                <Route
                  path={ROUTES.ENGAGEMENT_TAB}
                  element={<AdBoardEngagements />}
                />
                <Route path={ROUTES.PARTICIPANTS_TAB}>
                  <Route index element={<AdBoardParticipants />} />
                  <Route
                    path={':userId'}
                    element={<AdBoardIndividualParticipant />}
                  />
                </Route>
              </Route>
            </Route>
            <Route path={ROUTES.ENGAGEMENTS} element={<Engagements />} />
            <Route path={ROUTES.LOGOUT} element={<Logout />} />
          </Route>
        </Route>
        {/* Public routes goes here */}
        <Route element={<PublicRoutes />}>
          <Route element={<PublicRouteLayout />}>
            <Route index path={ROUTES.ROOT} element={<Login />} />
            <Route path={ROUTES.LOGIN} element={<Login />} />
            <Route path={ROUTES.FORGOT_PASSWORD} element={<ForgotPassword />} />
            <Route path={ROUTES.RESET_PASSWORD} element={<ResetPassword />} />
          </Route>
        </Route>
        <Route path={ROUTES.ADMIN_LOGIN_REDIRECT} element={<AdminLogin />} />
        <Route element={<PublicRouteLayout />}>
          <Route path={ROUTES.SIGNUP} element={<Signup />} />
        </Route>
        <Route path={'*'} element={<NotFound />} />
      </Route>
    </>
  )
);

export default router;
