import { apiClient } from './swagger-codegen-api-config';

export const getResultDataToExport = async (engagementId: string) => {
  try {
    const res =
      await apiClient.adBoardEngagementsApi.adBoardEngagementControllerResultsExport(
        engagementId,
        { responseType: 'blob' }
      );
    return res.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};
