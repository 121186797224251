import { Card, HStack, Text, VStack } from '@chakra-ui/react';

import { dateFormatter } from '../../../utils/functions/common-utils';
import i18n from '../../../utils/i18n';
import { AdBoardEngagementParticipantsDto } from '../types';

export const IndividualParticipantSummaryCard = ({
  currentParticipant,
}: {
  currentParticipant: AdBoardEngagementParticipantsDto;
}) => {
  return (
    <Card variant="outline" px={10} py={3}>
      <HStack spacing={12}>
        <VStack align="left">
          <Text fontSize="sm" color="gray.600">
            {i18n.t('adBoardDetails.individualParticipant.startedOn')}
          </Text>
          <Text fontSize="sm">
            {currentParticipant.progress?.startedOn
              ? dateFormatter(currentParticipant.progress.startedOn.toString())
              : '-'}
          </Text>
        </VStack>
        <VStack align="left">
          <Text fontSize="sm" color="gray.600">
            {i18n.t('adBoardDetails.individualParticipant.completedOn')}
          </Text>
          <Text fontSize="sm">
            {currentParticipant.progress?.finishedOn
              ? dateFormatter(currentParticipant.progress.finishedOn.toString())
              : '-'}
          </Text>
        </VStack>
        <VStack align="left">
          <Text fontSize="sm" color="gray.600">
            {i18n.t('adBoardDetails.individualParticipant.completionRate')}
          </Text>
          <Text fontSize="sm">{currentParticipant.completionPercentage}%</Text>
        </VStack>
      </HStack>
    </Card>
  );
};
