import { ChangeEvent, useCallback, useEffect, useMemo } from 'react';
import { useOutletContext, useSearchParams } from 'react-router-dom';
import {
  Box,
  Center,
  Divider,
  Grid,
  GridItem,
  HStack,
  Select,
  Text,
  useRadioGroup,
} from '@chakra-ui/react';

import SearchInputComponent from '../../components/SearchInputComponent';
import { capitalize } from '../../utils/functions/common-utils';
import i18n from '../../utils/i18n';
import EngagementDetails from '../EngagementDetails';

import { ActivityPreview } from './components/ActivityPreview';
import { FeedTable } from './components/FeedTable';
import { RadioCard } from './components/RadioCard';
import {
  AdBoardOutletContextType,
  AttachmentsAdBoardSectionDto,
  DiscussionAdBoardSectionContentDto,
  SectionDto,
  VideoAdBoardSectionDto,
} from './types';

// Type guard to narrow down to AttachmentsAdBoardSectionDto or VideoAdBoardSectionDto
const isPreviewableActivity = (
  activity: SectionDto
): activity is AttachmentsAdBoardSectionDto | VideoAdBoardSectionDto => {
  return activity.type === 'attachment' || activity.type === 'video';
};

type FilterOptionsType = {
  label: string;
  value: string;
};

const AdBoardEngagements = () => {
  const { adBoardData } = useOutletContext<AdBoardOutletContextType>();
  const [searchParams, setSearchParams] = useSearchParams();

  const sectionFromUrl = searchParams.get('section') || '';
  const activityFromUrl = searchParams.get('activity') || '';
  const topicFromUrl = searchParams.get('topic') || '';
  const searchFromUrl = searchParams.get('search') || '';

  const groupOptions: FilterOptionsType[] = useMemo(
    () =>
      adBoardData?.sectionGroups?.map((group) => ({
        label: `Section ${group.index + 1}`,
        value: group.id,
      })) || [],
    [adBoardData?.sectionGroups]
  );

  const selectedGroup = adBoardData?.sectionGroups?.find(
    (group) => group.id === sectionFromUrl
  );

  const activityOptions: FilterOptionsType[] = useMemo(
    () =>
      selectedGroup?.sections?.map((section: SectionDto) => ({
        label: `Activity ${section.index + 1} | ${capitalize(section.type)}: ${
          section.title
        }`,
        value: section.id,
      })) || [],
    [selectedGroup?.sections]
  );

  const selectedActivity = selectedGroup?.sections?.find(
    (activity: SectionDto) => activity.id === activityFromUrl
  );

  const topicOptions: FilterOptionsType[] = useMemo(
    () =>
      selectedActivity?.type === 'discussion'
        ? selectedActivity?.content?.map(
            (activityContent: DiscussionAdBoardSectionContentDto) => ({
              label: `Topic ${activityContent.index + 1} | ${
                activityContent?.discussion?.topic
              }`,
              value: activityContent.discussionId,
            })
          ) || []
        : [],
    [selectedActivity]
  );

  useEffect(() => {
    if (!sectionFromUrl && groupOptions.length > 0) {
      setSearchParams({
        section: groupOptions[0].value,
        activity: '',
        topic: '',
        search: searchFromUrl,
      });
    }
    if (!activityFromUrl && activityOptions.length > 0) {
      setSearchParams({
        section: sectionFromUrl,
        activity: activityOptions[0].value,
        topic: '',
        search: searchFromUrl,
      });
    }
    if (
      !topicFromUrl &&
      selectedActivity?.type === 'discussion' &&
      topicOptions.length > 0
    ) {
      setSearchParams({
        section: sectionFromUrl,
        activity: activityFromUrl,
        topic: topicOptions[0].value,
        search: searchFromUrl,
      });
    }
  }, [
    activityFromUrl,
    activityOptions,
    groupOptions,
    searchFromUrl,
    sectionFromUrl,
    selectedActivity?.type,
    setSearchParams,
    topicFromUrl,
    topicOptions,
  ]);

  const handleSectionSelect = (value: string) => {
    setSearchParams({
      section: value,
      activity: '',
      topic: '',
      search: searchFromUrl,
    });
  };

  const handleActivitySelect = (value: string) => {
    setSearchParams({
      section: sectionFromUrl,
      activity: value,
      topic: '',
      search: searchFromUrl,
    });
  };

  const handleTopicSelect = (value: string) => {
    setSearchParams({
      section: sectionFromUrl,
      activity: activityFromUrl,
      topic: value,
      search: searchFromUrl,
    });
  };

  const handleSearchQueryChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchParams({
      section: sectionFromUrl,
      activity: activityFromUrl,
      topic: topicFromUrl,
      search: event.target.value,
    });
  };
  const clearSearchQuery = () =>
    setSearchParams({
      section: sectionFromUrl,
      activity: activityFromUrl,
      topic: topicFromUrl,
      search: '',
    });

  const { getRootProps, getRadioProps } = useRadioGroup({
    name: 'section',
    value: sectionFromUrl,
    onChange: (value) => handleSectionSelect(value),
  });

  const getContent = useCallback(() => {
    if (!selectedGroup || !selectedActivity) return null;
    switch (selectedActivity?.type) {
      case 'video':
      case 'attachment':
      case 'discussion':
        return (
          <FeedTable
            selectedGroup={selectedGroup}
            selectedActivity={selectedActivity}
            selectedDiscussionId={topicFromUrl}
            searchQuery={searchFromUrl}
          />
        );
      case 'survey':
        return (
          <EngagementDetails
            adBoardSurveyEngagementId={
              selectedActivity.content.surveyEngagementId
            }
          />
        );
      default:
        return null;
    }
  }, [searchFromUrl, selectedActivity, selectedGroup, topicFromUrl]);

  return (
    <Box mt={4}>
      <HStack {...getRootProps()}>
        {groupOptions.map((option) => (
          <RadioCard
            key={option.value}
            {...getRadioProps({ value: option.value })}
          >
            {option.label}
          </RadioCard>
        ))}
      </HStack>

      <Box mt={6}>
        <Text fontSize="lg">{selectedGroup?.title}</Text>
        <Text fontSize="md">{selectedGroup?.description}</Text>
      </Box>

      <Box mt={6}>
        <Text fontSize="md" fontWeight={500} color="gray.600">
          {i18n.t('adBoardDetails.engagement.selectActivity')}
        </Text>
        <Select
          mt={2}
          size="md"
          value={activityFromUrl}
          onChange={(e) => handleActivitySelect(e.target.value)}
          aria-label="Select an activity"
        >
          {activityOptions.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </Select>
      </Box>

      {selectedActivity && isPreviewableActivity(selectedActivity) && (
        <ActivityPreview selectedActivity={selectedActivity} />
      )}

      <Center>
        <Divider mt={8} mb={6} w="70%" />
      </Center>

      {selectedActivity?.type !== 'survey' ? (
        <Grid templateColumns="repeat(2, 1fr)" gap={4}>
          <GridItem w="100%" alignContent={'end'}>
            <SearchInputComponent
              placeholder={i18n.t(
                'adBoardDetails.engagement.searchPlaceholder'
              )}
              value={searchFromUrl}
              onChange={handleSearchQueryChange}
              onClear={clearSearchQuery}
            />
          </GridItem>

          {selectedActivity?.type === 'discussion' && (
            <GridItem w="100%">
              <Text fontSize="sm" fontWeight={500} color="gray.600">
                {i18n.t('adBoardDetails.engagement.filterByTopic')}
              </Text>
              <Select
                size="md"
                value={topicFromUrl}
                onChange={(e) => handleTopicSelect(e.target.value)}
              >
                {topicOptions.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </Select>
            </GridItem>
          )}
        </Grid>
      ) : (
        <></>
      )}
      {getContent()}
    </Box>
  );
};

export { AdBoardEngagements };
