import { Card, CardBody, Text } from '@chakra-ui/react';

const SummaryDemoCard = ({
  title,
  value,
}: {
  title: string;
  value: string | number;
}) => {
  return (
    <Card variant={'outline'}>
      <CardBody py={2} px={3}>
        <Text fontSize="md" fontWeight={300}>
          {title}
        </Text>
        <Text mt={1} fontSize="lg">
          {value}
        </Text>
      </CardBody>
    </Card>
  );
};

export default SummaryDemoCard;
